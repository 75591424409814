import { Grid } from '@mui/material';
import React, { useContext, useState } from 'react';
import TextInput from '../../../Common/TextInput';
import { t } from '../../../../types/translation/Translator';
import { CompanyContext } from '../../../../context/CompanyContext';
import ColorPicker from '../../../Common/ColorPicker';
import { Company } from '../../../../types/company';
import { useMutation } from '@apollo/client';
import { CompanyMutations, UpdateCompanyResponse, UpdateCompanyVariables } from '../../../../graphql/company.graphql';
import { testIds } from '../../../../util/identifiers/identifiers.util';
import { FlexPane } from '../../../../VentoryUI/components/common/FlexPane/FlexPane';
import Paper from '../../../../VentoryUI/components/common/Paper/Paper';
import UploadFileInput from '../../../../VentoryUI/components/common/File/UploadFileInput';
import { CreateFilesResponse, CreateFilesVariables, FileMutations } from '../../../../graphql/file.graphql';
import { FileEntity, CreateFileInput, FileEntityType } from '../../../../types/file';
import { FileContext } from '../../../../context/FileContext';

interface CompanyThemePaneInputProps {
  footer: (company: Company) => JSX.Element;
  setError: (error: string) => void;
}

export default function CompanyThemePane({ footer, setError }: CompanyThemePaneInputProps) {
  const { currentCompany, setCurrentCompany } = useContext(CompanyContext);

  const [companyInput, setCompanyInput] = useState<Company>(new Company(currentCompany));

  const { files, setFiles } = useContext(FileContext);

  const [create, { loading }] = useMutation<CreateFilesResponse, CreateFilesVariables>(FileMutations.create, {
    onCompleted: result => {
      result.createFiles.forEach(file => files.set(file.id, new FileEntity(file)));
      setFiles(new Map(files));
    },
    onError: error => setError(error.message),
  });

  const [update, { loading: updateLoading }] = useMutation<UpdateCompanyResponse, UpdateCompanyVariables>(
    CompanyMutations.update,
    {
      onCompleted: res => {
        const company = res.updateCompany;
        setCompanyInput(new Company(company));
        setCurrentCompany(new Company(company));
      },
    },
  );

  const handleLogoUpload = async (files: FileList | null) => {
    if (!files?.length) {
      currentCompany.settings.companyLogo = '';
      await update({
        variables: {
          company: currentCompany.forUpdate(),
        },
      });
      return;
    }

    const file = files[0];
    CreateFileInput.fromFile(file, currentCompany.id, FileEntityType.company, currentCompany.id, async result => {
      const response = await create({
        variables: {
          files: [result],
        },
      });

      const fileId = response.data?.createFiles[0].id;
      if (!fileId) return setError('Something went wrong uploading your file, please try again later');

      currentCompany.settings.companyLogo = fileId;
      await update({
        variables: {
          company: currentCompany.forUpdate(),
        },
      });
    });
  };

  const handleFaviconUpload = async (files: FileList | null) => {
    if (!files?.length) {
      currentCompany.settings.companyFavicon = '';
      await update({
        variables: {
          company: currentCompany.forUpdate(),
        },
      });
      return;
    }

    const file = files[0];
    CreateFileInput.fromFile(file, currentCompany.id, FileEntityType.company, currentCompany.id, async result => {
      const response = await create({
        variables: {
          files: [result],
        },
      });

      const fileId = response.data?.createFiles[0].id;
      if (!fileId) return setError('Something went wrong uploading your file, please try again later');

      currentCompany.settings.companyFavicon = fileId;
      await update({
        variables: {
          company: currentCompany.forUpdate(),
        },
      });
    });
  };

  return (
    <FlexPane
      testId={testIds.companyThemePane}
      content={
        <Paper>
          <Grid container columnSpacing={2}>
            <Grid item xs={6}>
              <Grid container rowSpacing={2}>
                <Grid item xs={12}>
                  <TextInput
                    mandatory
                    label={t().companyName.singular.label}
                    placeholder={t().companyName.singular.label}
                    value={companyInput.name}
                    onChange={v => companyInput.withName(v)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <p className='text-md text-slate-800 text-[14px] mb-1 font-medium'>
                    {t().companyLogo.singular.label}
                  </p>
                  <UploadFileInput
                    fileEntityType={FileEntityType.company}
                    acceptType='image'
                    loading={loading || updateLoading}
                    height='240px'
                    file={currentCompany.settings.companyLogo}
                    onFile={handleLogoUpload}
                  />
                </Grid>
                <Grid item xs={12}>
                  <p className='text-md text-slate-800 text-[14px] mb-1 font-medium'>
                    {t().companyFavicon.singular.label}
                  </p>
                  <UploadFileInput
                    fileEntityType={FileEntityType.company}
                    acceptType='image'
                    loading={loading || updateLoading}
                    height='240px'
                    file={currentCompany.settings.companyFavicon}
                    onFile={handleFaviconUpload}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container rowSpacing={1}>
                <Grid item xs={12} md={6}>
                  <p className='text-center text-sm font-medium text-slate-800'>{t().primaryColor.singular.label}</p>
                  <ColorPicker
                    color={companyInput.settings.accentColor}
                    defaultColor={'#00F28D'}
                    setColor={v => setCompanyInput(companyInput.withAccentColor(v))}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <p className='text-center text-sm font-medium text-slate-800'>
                    {t().headerBackground.singular.label}
                  </p>
                  <ColorPicker
                    color={companyInput.settings.headerColor}
                    defaultColor={'#181E29'}
                    setColor={v => setCompanyInput(companyInput.withHeaderColor(v))}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      }
      footer={footer(companyInput)}
    />
  );
}
