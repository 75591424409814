import { Grid } from '@mui/material';
import React, { useMemo } from 'react';
import { Company, CompanyStatus } from '../../types/company';
import { ReactComponent as VentoryWhiteLogo } from '../../assets/img/Ventory-WhiteNoBack.svg';
import FileViewLoader from '../../VentoryUI/components/common/File/FileViewLoader';
import { FileEntityType } from '../../types/file';
import Color from 'color';

interface CompanySelectFrameProps {
  company: Company;
  onClick: () => void;
  showBorderColor?: boolean;
}

export default function CompanySelectFrame({ company, onClick, showBorderColor = false }: CompanySelectFrameProps) {
  const borderColor = useMemo(() => {
    switch (company.status) {
      case CompanyStatus.trial:
        return 'border-blue-400';
      case CompanyStatus.active:
        return 'border-green-400';
      case CompanyStatus.archive:
        return 'border-gray-400';
    }
  }, [company]);

  return (
    <Grid item className='w-40 h-40 p-1' sx={{}}>
      <Grid
        container
        onClick={onClick}
        alignContent={'center'}
        className={`${
          showBorderColor ? `border-solid border-4 ${borderColor}` : 'border'
        } w-full h-full cursor-pointer rounded-lg`}
        bgcolor={company.settings.headerColor}
      >
        <Grid item xs={12} display={'flex'} justifyContent={'center'} className='py-[4px]'>
          {company.settings.companyLogo ? (
            <FileViewLoader
              companyId={company.id}
              fileEntityType={FileEntityType.company}
              fileId={company.settings.companyLogo}
              className='max-h-8 max-w-[90%] my-auto'
            />
          ) : company.settings.companyFavicon ? (
            <FileViewLoader
              companyId={company.id}
              fileEntityType={FileEntityType.company}
              fileId={company.settings.companyFavicon}
              className='max-h-8 max-w-[90%] my-auto'
            />
          ) : (
            <VentoryWhiteLogo style={{ maxHeight: '80px' }} />
          )}
        </Grid>
        <Grid item xs={12} textAlign={'center'} pt={1}>
          <p
            className='text-sm mx-4 select-none text-ellipsis overflow-hidden whitespace-nowrap'
            style={{ color: new Color(company.settings.headerColor).isDark() ? 'white' : 'black' }}
          >
            {company.name}
          </p>
        </Grid>
      </Grid>
    </Grid>
  );
}
