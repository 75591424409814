import { gql } from '@apollo/client';
import { VentorySubscription } from '../types/billing';

const billing = gql`
  fragment VentorySubscription on VentorySubscription {
    id
    version
    companyId
    service
    type
    state
    externalId
    expiresOn
  }
`;

const get = gql`
  query Subscription($companyId: String!) {
    subscription(companyId: $companyId) {
      ...VentorySubscription
    }
  }
  ${billing}
`;

export interface GetVentorySubscriptionVariables {
  companyId: string;
}

export interface GetVentorySubscriptionResponse {
  subscription?: VentorySubscription;
}

const stripeCustomerURL = gql`
  query StripeCustomerPortalUrl($companyId: String!, $redirectUrl: String!) {
    stripeCustomerPortalUrl(companyId: $companyId, redirectUrl: $redirectUrl)
  }
`;

export interface GetStripeCustomerURLVariables {
  companyId: string;
  redirectUrl: string;
}

export interface GetStripeCustomerURLResponse {
  stripeCustomerPortalUrl: string;
}

export const BillingQueries = {
  get,
  stripeCustomerURL,
};
