export enum TranslationKey {
  accept = 'accept',
  acceptedFormats = 'acceptedFormats',
  actions = 'actions',
  add = 'add',
  addAdditionalOrderExportField = 'addAdditionalOrderExportField',
  addAdditionalProductExportField = 'addAdditionalProductExportField',
  addAdditionalTaskExportField = 'addAdditionalTaskExportField',
  addDueDate = 'addDueDate',
  addFilter = 'addFilter',
  additionalProductIdentificationAssignedByTheManufacturer = 'additionalProductIdentificationAssignedByTheManufacturer',
  additionalSettings = 'additionalSettings',
  addOrderColumn = 'addOrderColumn',
  addProduct = 'addProduct',
  addProductsToOrder = 'addProductsToOrder',
  addProductTransactionColumn = 'addProductTransactionColumn',
  addressLine1 = 'addressLine1',
  addressLine2 = 'addressLine2',
  administrator = 'administrator',
  advancedSettings = 'advancedSettings',
  alert = 'alert',
  alertsAlertFeatureToggle = 'alertsAlertFeatureToggle',
  all = 'all',
  allOf = 'allOf',
  alreadyHaveAccountQuestion = 'alreadyHaveAccountQuestion',
  anAlertHasBeenTriggeredText = 'anAlertHasBeenTriggeredText',
  any = 'any',
  anyOf = 'anyOf',
  apiKey = 'apiKey',
  apiKeyForUserAlreadyExistsWithinCompany = 'apiKeyForUserAlreadyExistsWithinCompany',
  applicationPartIncident = 'applicationPartIncident',
  appliesToAllProducts = 'appliesToAllProducts',
  attachements = 'attachements',
  attributes = 'attributes',
  authentication = 'authentication',
  authenticationCodeForUserAlreadyExists = 'authenticationCodeForUserAlreadyExists',
  authenticationCodeHasExpired = 'authenticationCodeHasExpired',
  authenticationSettingsForUserAlreadyExists = 'authenticationSettingsForUserAlreadyExists',
  authenticationType = 'authenticationType',
  back = 'back',
  barcode = 'barcode',
  barcodeConfigurations = 'barcodeConfigurations',
  barcodeConfigurationType = 'barcodeConfigurationType',
  barcodeFormat = 'barcodeFormat',
  bestBeforeDate = 'bestBeforeDate',
  billing = 'billing',
  billToInvoiceToGlobalLocationNumber = 'billToInvoiceToGlobalLocationNumber',
  bin = 'bin',
  binHasNoStockLocation = 'binHasNoStockLocation',
  binMetrics = 'binMetrics',
  binName = 'binName',
  binStatus = 'binStatus',
  binStatusWithNameAlreadyExists = 'binStatusWithNameAlreadyExists',
  binWithNameAlreadyExists = 'binWithNameAlreadyExists',
  binWithNameAlreadyExistsForStockLocation = 'binWithNameAlreadyExistsForStockLocation',
  blindCount = 'blindCount',
  browse = 'browse',
  businessCentralUnableToDetermineSourceStockLocation = 'businessCentralUnableToDetermineSourceStockLocation',
  businessCentralUnableToFindIdentifierToCreateJournalEntry = 'businessCentralUnableToFindIdentifierToCreateJournalEntry',
  businessCentralUnableToFindParentOrder = 'businessCentralUnableToFindParentOrder',
  businessCentralUnableToFindProjektasKodas = 'businessCentralUnableToFindProjektasKodas',
  businessCentralUnableToFindProjektasKodasValue = 'businessCentralUnableToFindProjektasKodasValue',
  businessCentralUnableToProcessWhenStockLocationIsNot = 'businessCentralUnableToProcessWhenStockLocationIsNot',
  businessCentralUnableToUpdateTransactions = 'businessCentralUnableToUpdateTransactions',
  cancel = 'cancel',
  cancelled = 'cancelled',
  cancelOrder = 'cancelOrder',
  cancelOrderValidation = 'cancelOrderValidation',
  cancelTransaction = 'cancelTransaction',
  cannotAssignUserNotAssignedToStockLocation = 'cannotAssignUserNotAssignedToStockLocation',
  cannotAssignUserWithoutRoleInCompany = 'cannotAssignUserWithoutRoleInCompany',
  cannotCreateTaskWithoutDueDate = 'cannotCreateTaskWithoutDueDate',
  cannotDeleteExistingProductsIfStockLocationNotUndefined = 'cannotDeleteExistingProductsIfStockLocationNotUndefined',
  cellularMobileTelephoneIdentifier = 'cellularMobileTelephoneIdentifier',
  changePassword = 'changePassword',
  city = 'city',
  clearAll = 'clearAll',
  clickToSelectAndDisplayProductImage = 'clickToSelectAndDisplayProductImage',
  clientId = 'clientId',
  clientSecret = 'clientSecret',
  cm = 'cm',
  code = 'code',
  collapse = 'collapse',
  color = 'color',
  comment = 'comment',
  company = 'company',
  companyAlreadyHasSubscription = 'companyAlreadyHasSubscription',
  companyColors = 'companyColors',
  companyDoesNotHaveAnyStockLocations = 'companyDoesNotHaveAnyStockLocations',
  companyFavicon = 'companyFavicon',
  companyInternalInformation = 'companyInternalInformation',
  companyLevel = 'companyLevel',
  companyLogo = 'companyLogo',
  companyName = 'companyName',
  complete = 'complete',
  completed = 'completed',
  completedOn = 'completedOn',
  completeOrder = 'completeOrder',
  completeOrderValidation = 'completeOrderValidation',
  componentPartIdentifier = 'componentPartIdentifier',
  componentPartIdentifierSerialNumber = 'componentPartIdentifierSerialNumber',
  confirm = 'confirm',
  confirmPassword = 'confirmPassword',
  consumerProductVariant = 'consumerProductVariant',
  contact = 'contact',
  contactEmail = 'contactEmail',
  contactLocationWithNameXNotPartOfContactY = 'contactLocationWithNameXNotPartOfContactY',
  contacts = 'contacts',
  contactWithNameAlreadyExists = 'contactWithNameAlreadyExists',
  contactWithNameXNotFound = 'contactWithNameXNotFound',
  countedOnly = 'countedOnly',
  countOfTradeItemsContained = 'countOfTradeItemsContained',
  country = 'country',
  countryCoveringFullProcessChain = 'countryCoveringFullProcessChain',
  countryOfDisassembly = 'countryOfDisassembly',
  countryOfInitialProcessing = 'countryOfInitialProcessing',
  countryOfOrigin = 'countryOfOrigin',
  countryOfOriginOfTradeItem = 'countryOfOriginOfTradeItem',
  countryOfProcessing = 'countryOfProcessing',
  couponCodeIdentificationNorthAmerica = 'couponCodeIdentificationNorthAmerica',
  create = 'create',
  createBarcodeConfiguration = 'createBarcodeConfiguration',
  createBin = 'createBin',
  createBinStatus = 'createBinStatus',
  createCompany = 'createCompany',
  createContact = 'createContact',
  createCustomField = 'createCustomField',
  created = 'created',
  createdAt = 'createdAt',
  createdBy = 'createdBy',
  createDemoCompany = 'createDemoCompany',
  createIntegration = 'createIntegration',
  createNewCompany = 'createNewCompany',
  createOrder = 'createOrder',
  createProductMasterData = 'createProductMasterData',
  createReplenishOrderAuto = 'createReplenishOrderAuto',
  createScanRule = 'createScanRule',
  createShippingLocation = 'createShippingLocation',
  createStockLocation = 'createStockLocation',
  createStockLocationRole = 'createStockLocationRole',
  createSuperUser = 'createSuperUser',
  createTask = 'createTask',
  createTrigger = 'createTrigger',
  creatingInboundOrdersNotAllowedForElteros = 'creatingInboundOrdersNotAllowedForElteros',
  csvUploadConfigurationNamePlaceholder = 'csvUploadConfigurationNamePlaceholder',
  currency = 'currency',
  currentMonth = 'currentMonth',
  customer = 'customer',
  customerPartNumber = 'customerPartNumber',
  customerPurchaseOrderNumber = 'customerPurchaseOrderNumber',
  customFieldIsRequiredForProductTransactions = 'customFieldIsRequiredForProductTransactions',
  customFieldRequiesEntityParentType = 'customFieldRequiesEntityParentType',
  customFieldRequiresEntitySubtype = 'customFieldRequiresEntitySubtype',
  customFields = 'customFields',
  customFieldWithIdNotFound = 'customFieldWithIdNotFound',
  customFieldWithNameAlreadyExists = 'customFieldWithNameAlreadyExists',
  customFieldXUsedForValidationButDoesNotMatch = 'customFieldXUsedForValidationButDoesNotMatch',
  customValue = 'customValue',
  cycleCount = 'cycleCount',
  dashboard = 'dashboard',
  dateAndTime = 'dateAndTime',
  dateAndTimeOfProduction = 'dateAndTimeOfProduction',
  dateInFuture = 'dateInFuture',
  dateInPast = 'dateInPast',
  day = 'day',
  decline = 'decline',
  default = 'default',
  defaultValue = 'defaultValue',
  delete = 'delete',
  deleteAllExistingBins = 'deleteAllExistingBins',
  delimiter = 'delimiter',
  depth = 'depth',
  destinationBin = 'destinationBin',
  destinationNumber = 'destinationNumber',
  destinationStockLocation = 'destinationStockLocation',
  developerOptions = 'developerOptions',
  discrepancyOnly = 'discrepancyOnly',
  discrepancyThreshold = 'discrepancyThreshold',
  dm = 'dm',
  done = 'done',
  downloadTemplate = 'downloadTemplate',
  due = 'due',
  dueDate = 'dueDate',
  dueDateMandatory = 'dueDateMandatory',
  duplicateLPNFoundOnRows = 'duplicateLPNFoundOnRows',
  edit = 'edit',
  email = 'email',
  employee = 'employee',
  entitiesNotFound = 'entitiesNotFound',
  entityAlreadyExistsInDatabase = 'entityAlreadyExistsInDatabase',
  entityAlreadyUpdatedInTheDatabase = 'entityAlreadyUpdatedInTheDatabase',
  entityNotFound = 'entityNotFound',
  entityParentType = 'entityParentType',
  entitySubtype = 'entitySubtype',
  entityType = 'entityType',
  entityXNotFound = 'entityXNotFound',
  environment = 'environment',
  estimatedTimeOfArrival = 'estimatedTimeOfArrival',
  expectedQuantity = 'expectedQuantity',
  expirationDate = 'expirationDate',
  expirationDateAndTime = 'expirationDateAndTime',
  expiryInHours = 'expiryInHours',
  export = 'export',
  exportOrders = 'exportOrders',
  exportProductReferences = 'exportProductReferences',
  exportProducts = 'exportProducts',
  exportProductTransactions = 'exportProductTransactions',
  externalId = 'externalId',
  externalReference = 'externalReference',
  externalReferenceId = 'externalReferenceId',
  featureCategory = 'featureCategory',
  featureToggles = 'featureToggles',
  field = 'field',
  fieldUser = 'fieldUser',
  fileFormatCsv = 'fileFormatCsv',
  fileName = 'fileName',
  files = 'files',
  filesAndAssets = 'filesAndAssets',
  filterAlerts = 'filterAlerts',
  filterBarcodeConfigurations = 'filterBarcodeConfigurations',
  filterBins = 'filterBins',
  filterBinStatus = 'filterBinStatus',
  filterContacts = 'filterContacts',
  filterCountries = 'filterCountries',
  filterCustomFields = 'filterCustomFields',
  filterFiles = 'filterFiles',
  filterIntegrations = 'filterIntegrations',
  filterOrders = 'filterOrders',
  filterOrderStatus = 'filterOrderStatus',
  filterProductReferenceData = 'filterProductReferenceData',
  filterProducts = 'filterProducts',
  filterProductTransactions = 'filterProductTransactions',
  filterReorderRules = 'filterReorderRules',
  filter = 'filter',
  filterScanRules = 'filterScanRules',
  filterShippingLocations = 'filterShippingLocations',
  filterStockLocations = 'filterStockLocations',
  filterTasks = 'filterTasks',
  filterTaskStatus = 'filterTaskStatus',
  filterTriggers = 'filterTriggers',
  filterUsers = 'filterUsers',
  finish = 'finish',
  firstName = 'firstName',
  forgotPassword = 'forgotPassword',
  forTheFollowingStockLocations = 'forTheFollowingStockLocations',
  foundTwoDifferentValuesForSameCustomFieldPropertyForOrderXOnRowY = 'foundTwoDifferentValuesForSameCustomFieldPropertyForOrderXOnRowY',
  foundTwoDifferentValuesForSamePropertyForOrderXOnRowY = 'foundTwoDifferentValuesForSamePropertyForOrderXOnRowY',
  g = 'g',
  general = 'general',
  generateApiKey = 'generateApiKey',
  globalCouponNumber = 'globalCouponNumber',
  globalDocumentTypeIdentifier = 'globalDocumentTypeIdentifier',
  globalIdentifierNumberForConsignment = 'globalIdentifierNumberForConsignment',
  globalIndividualAssetIdentifier = 'globalIndividualAssetIdentifier',
  globalLocationNumber = 'globalLocationNumber',
  globalLocationNumberExtensionComponent = 'globalLocationNumberExtensionComponent',
  globalLocationNumberInvoicingParty = 'globalLocationNumberInvoicingParty',
  globalModelNumber = 'globalModelNumber',
  globalReturnableAssetIdentifier = 'globalReturnableAssetIdentifier',
  globalServiceRelationNumberProvider = 'globalServiceRelationNumberProvider',
  globalServiceRelationNumberRecipient = 'globalServiceRelationNumberRecipient',
  globalShipmentIdentificationNumber = 'globalShipmentIdentificationNumber',
  globalTradeItemNumber = 'globalTradeItemNumber',
  globalTradeItemNumberOfTradeItemsContained = 'globalTradeItemNumberOfTradeItemsContained',
  grossWeight = 'grossWeight',
  headerBackground = 'headerBackground',
  height = 'height',
  hexColorCode = 'hexColorCode',
  history = 'history',
  hour = 'hour',
  identificationOfIndividualTradeItem = 'identificationOfIndividualTradeItem',
  ignoreAvailableQuantity = 'ignoreAvailableQuantity',
  implementMe = 'implementMe',
  import = 'import',
  importance = 'importance',
  importBins = 'importBins',
  importConfigurations = 'importConfigurations',
  importOrders = 'importOrders',
  importProduct = 'importProduct',
  importProductMasterData = 'importProductMasterData',
  importStockLocations = 'importStockLocations',
  importUsers = 'importUsers',
  inbound = 'inbound',
  inboundAllowed = 'inboundAllowed',
  inboundOrder = 'inboundOrder',
  incidentApplication = 'incidentApplication',
  incidentApplicationPart = 'incidentApplicationPart',
  incidentReportDescriptionMayNotBeEmptyOrUndefined = 'incidentReportDescriptionMayNotBeEmptyOrUndefined',
  incidentReportDeviceMayNotBeEmptyOrUndefinedIfApplicationMobile = 'incidentReportDeviceMayNotBeEmptyOrUndefinedIfApplicationMobile',
  incidentReportMobileElementMustBeSelectedIfApplicationMobile = 'incidentReportMobileElementMustBeSelectedIfApplicationMobile',
  incidentReportOperatingSystemMayNotBeEmptyOrUndefinedIfApplicationMobile = 'incidentReportOperatingSystemMayNotBeEmptyOrUndefinedIfApplicationMobile',
  incidentReportWebElementMustBeSelectedIfApplicationWeb = 'incidentReportWebElementMustBeSelectedIfApplicationWeb',
  incorrectCode = 'incorrectCode',
  info = 'info',
  informationMutuallyAgreedBetweenTradingPartners = 'informationMutuallyAgreedBetweenTradingPartners',
  inProgress = 'inProgress',
  insufficientQuantityAvailableInBin = 'insufficientQuantityAvailableInBin',
  integrations = 'integrations',
  internalProductVariant = 'internalProductVariant',
  internationalBankAccountNumber = 'internationalBankAccountNumber',
  invalidDate = 'invalidDate',
  invalidOrderStatusOnRowX = 'invalidOrderStatusOnRowX',
  invalidOrderTypeOnRowX = 'invalidOrderTypeOnRowX',
  invalidQuantity = 'invalidQuantity',
  invalidQuantityX = 'invalidQuantityX',
  invalidTriggerEntityValueText = 'invalidTriggerEntityValueText',
  invite = 'invite',
  inviteUser = 'inviteUser',
  isToday = 'isToday',
  isTomorrow = 'isTomorrow',
  isYesterday = 'isYesterday',
  itemDoesNotHaveProperty = 'itemDoesNotHaveProperty',
  jobForStockLocationIdentifierIsNotOpen = 'jobForStockLocationIdentifierIsNotOpen',
  jobForTransactionIsNotOpen = 'jobForTransactionIsNotOpen',
  kg = 'kg',
  language = 'language',
  lastmonth = 'lastmonth',
  lastName = 'lastName',
  lastweek = 'lastweek',
  lb = 'lb',
  length = 'length',
  location = 'location',
  locations = 'locations',
  lotHasExpiredAlertText = 'lotHasExpiredAlertText',
  lotManaged = 'lotManaged',
  lotNumber = 'lotNumber',
  lotTriggerValueText = 'lotTriggerValueText',
  lotWillExpireAlertText = 'lotWillExpireAlertText',
  lotWithNumberAlreadyExistsForProductReference = 'lotWithNumberAlreadyExistsForProductReference',
  lpn = 'lpn',
  lpnManaged = 'lpnManaged',
  m = 'm',
  madeToOrderVariationNumber = 'madeToOrderVariationNumber',
  manager = 'manager',
  mandatory = 'mandatory',
  manufacturer = 'manufacturer',
  manufacturerCode = 'manufacturerCode',
  manyAlertsHaveBeenTriggeredText = 'manyAlertsHaveBeenTriggeredText',
  map = 'map',
  mapTheFollowingFields = 'mapTheFollowingFields',
  mapUnavailable = 'mapUnavailable',
  mass = 'mass',
  max = 'max',
  missingMandatoryCustomFieldXOnRowY = 'missingMandatoryCustomFieldXOnRowY',
  missingRequiredFieldMapping = 'missingRequiredFieldMapping',
  missingValueForRequiredCustomFieldX = 'missingValueForRequiredCustomFieldX',
  mm = 'mm',
  mobileStockLocation = 'mobileStockLocation',
  mustSelectAtLeastOneStockLocationRoleForInitialCount = 'mustSelectAtLeastOneStockLocationRoleForInitialCount',
  mustSelectAtLeastOneStockLocationRoleForRecountIfDiscrepancyThresholdIsHigherThan0 = 'mustSelectAtLeastOneStockLocationRoleForRecountIfDiscrepancyThresholdIsHigherThan0',
  name = 'name',
  natoStockNumber = 'natoStockNumber',
  netWeight = 'netWeight',
  networkConnection = 'networkConnection',
  networkConnectionOccurrence = 'networkConnectionOccurrence',
  new = 'new',
  newComment = 'newComment',
  newPassword = 'newPassword',
  next = 'next',
  no = 'no',
  noAccountYetQuestion = 'noAccountYetQuestion',
  noAddress = 'noAddress',
  noCompaniesFound = 'noCompaniesFound',
  noItemsFound = 'noItemsFound',
  noJobsFound = 'noJobsFound',
  noLinesFoundForPurchaseOrder = 'noLinesFoundForPurchaseOrder',
  noLocationsFound = 'noLocationsFound',
  noProductReferenceFound = 'noProductReferenceFound',
  noPurchaseOrdersFound = 'noPurchaseOrdersFound',
  noSettingsFoundForBusinessCentral = 'noSettingsFoundForBusinessCentral',
  noStockLocationIdOrStockLocationMappingProvided = 'noStockLocationIdOrStockLocationMappingProvided',
  noStockLocationsGiven = 'noStockLocationsGiven',
  noValidTaskInputFound = 'noValidTaskInputFound',
  nrOfDigits = 'nrOfDigits',
  numberOfCounts = 'numberOfCounts',
  numberOfOrders = 'numberOfOrders',
  numberOfTransactions = 'numberOfTransactions',
  oldPassword = 'oldPassword',
  only1CustomFieldPerEntitySubtypeSupported = 'only1CustomFieldPerEntitySubtypeSupported',
  open = 'open',
  operatingSystemVersion = 'operatingSystemVersion',
  optionalPhoneNumber = 'optionalPhoneNumber',
  or = 'or',
  order = 'order',
  orderEditProductsOnInbound = 'orderEditProductsOnInbound',
  orderEditProductsOnOutbound = 'orderEditProductsOnOutbound',
  orderMetrics = 'orderMetrics',
  orderNumbers = 'orderNumbers',
  orderOrderFeatureToggle = 'orderOrderFeatureToggle',
  orderProductSelectionFirst = 'orderProductSelectionFirst',
  orderReportConfigurationWithNameAlreadyExists = 'orderReportConfigurationWithNameAlreadyExists',
  orderSettings = 'orderSettings',
  orderStatus = 'orderStatus',
  orderType = 'orderType',
  orderWithNumberXMissingMandatoryCustomFieldY = 'orderWithNumberXMissingMandatoryCustomFieldY',
  orderWithNumberXMissingStockLocationNameOrIdentifier = 'orderWithNumberXMissingStockLocationNameOrIdentifier',
  orderWithNumberXMissingType = 'orderWithNumberXMissingType',
  originalEquipmentManufacturer = 'originalEquipmentManufacturer',
  originalEquipmentManufacturerCode = 'originalEquipmentManufacturerCode',
  originalEquipmentManufacturerCodePlacehoder = 'originalEquipmentManufacturerCodePlacehoder',
  outbound = 'outbound',
  outboundAllowed = 'outboundAllowed',
  outboundOrder = 'outboundOrder',
  overdue = 'overdue',
  overview = 'overview',
  oz = 'oz',
  pack = 'pack',
  packagingDate = 'packagingDate',
  password = 'password',
  passwordRequirementInfo = 'passwordRequirementInfo',
  paymentSlipReferenceNumber = 'paymentSlipReferenceNumber',
  pleaseDefineDefaultBin = 'pleaseDefineDefaultBin',
  pleaseSelectConfigurationsForProductReferenceExport = 'pleaseSelectConfigurationsForProductReferenceExport',
  pmdQuantityAlertText = 'pmdQuantityAlertText',
  postalCode = 'postalCode',
  prefix = 'prefix',
  pricePerUnitOfMeasure = 'pricePerUnitOfMeasure',
  primarilyBenifitFeature = 'primarilyBenifitFeature',
  primaryColor = 'primaryColor',
  priority = 'priority',
  process = 'process',
  processed = 'processed',
  processedAt = 'processedAt',
  processedBy = 'processedBy',
  processedQuantityExceedsExpectedQuantity = 'processedQuantityExceedsExpectedQuantity',
  processQuantity = 'processQuantity',
  processTransaction = 'processTransaction',
  product = 'product',
  productAlreadyExists = 'productAlreadyExists',
  productImage = 'productImage',
  productImportExistingConfigurationPlaceholder = 'productImportExistingConfigurationPlaceholder',
  productionDate = 'productionDate',
  productMasterData = 'productMasterData',
  productMasterDataNotDefined = 'productMasterDataNotDefined',
  productMetrics = 'productMetrics',
  productName = 'productName',
  productNumber = 'productNumber',
  productReferenceWithNumberXNotFound = 'productReferenceWithNumberXNotFound',
  productReferenceWithProductNumberAlreadyExists = 'productReferenceWithProductNumberAlreadyExists',
  productReportConfigurationWithNameAlreadyExists = 'productReportConfigurationWithNameAlreadyExists',
  productsGS1Toggle = 'productsGS1Toggle',
  productsProductToggle = 'productsProductToggle',
  productTransactionAlertCustomFieldNotFoundText = 'productTransactionAlertCustomFieldNotFoundText',
  productTransactionAlertOrderText = 'productTransactionAlertOrderText',
  productTransactionAlertUnknownOrderText = 'productTransactionAlertUnknownOrderText',
  productTransactionDueDateAlertText = 'productTransactionDueDateAlertText',
  productTransactionDueDateTriggerText = 'productTransactionDueDateTriggerText',
  productTransactionFieldUpdatedAlertText = 'productTransactionFieldUpdatedAlertText',
  productTransactionReportConfigurationWithNameAlreadyExists = 'productTransactionReportConfigurationWithNameAlreadyExists',
  productTransactionsMobile = 'productTransactionsMobile',
  productTransactionsProductTransactionFeatureToggle = 'productTransactionsProductTransactionFeatureToggle',
  productTransactionsQuickActions = 'productTransactionsQuickActions',
  productTransactionsWeb = 'productTransactionsWeb',
  productTransactionTriggerRequiresAtLeastOneCustomField = 'productTransactionTriggerRequiresAtLeastOneCustomField',
  productWithLPNAlreadyExists = 'productWithLPNAlreadyExists',
  productWIthLPNOnRowAlreadyExists = 'productWIthLPNOnRowAlreadyExists',
  productWithLPNOnRowAlreadyExistsInOtherStockLocation = 'productWithLPNOnRowAlreadyExistsInOtherStockLocation',
  productWithSerialAlreadyExists = 'productWithSerialAlreadyExists',
  productWithSerialOnRowAlreadyExists = 'productWithSerialOnRowAlreadyExists',
  profile = 'profile',
  purchasedFromGlobalLocationNumber = 'purchasedFromGlobalLocationNumber',
  purchaseOrderNumber = 'purchaseOrderNumber',
  purchasePrice = 'purchasePrice',
  pushNotification = 'pushNotification',
  quantity = 'quantity',
  raiseOnBehalfOf = 'raiseOnBehalfOf',
  reassignUsers = 'reassignUsers',
  recount = 'recount',
  referenceData = 'referenceData',
  referenceToSourceEntity = 'referenceToSourceEntity',
  refreshCompanyCache = 'refreshCompanyCache',
  region = 'region',
  released = 'released',
  reorderPoint = 'reorderPoint',
  reorderRuleForProductStockLocationAndAggregateTypeAlreadyExists = 'reorderRuleForProductStockLocationAndAggregateTypeAlreadyExists',
  reorderUserRuleForProductAndStockLocationAlreadyExists = 'reorderUserRuleForProductAndStockLocationAlreadyExists',
  reorderUsers = 'reorderUsers',
  replenish = 'replenish',
  reportIncident = 'reportIncident',
  reporting = 'reporting',
  reportOnBehalfOf = 'reportOnBehalfOf',
  requestNewCode = 'requestNewCode',
  requestType = 'requestType',
  reset = 'reset',
  resetToDefault = 'resetToDefault',
  role = 'role',
  rolesForInitialCount = 'rolesForInitialCount',
  rolesForRecount = 'rolesForRecount',
  rollback = 'rollback',
  rollProducts = 'rollProducts',
  routingCode = 'routingCode',
  rowMustContainOrderNumber = 'rowMustContainOrderNumber',
  save = 'save',
  scan = 'scan',
  scanRules = 'scanRules',
  secondarySerialNumber = 'secondarySerialNumber',
  selectAll = 'selectAll',
  selectAnImage = 'selectAnImage',
  selectAProduct = 'selectAProduct',
  selectAssignedOrderUsers = 'selectAssignedOrderUsers',
  selectBins = 'selectBins',
  selectBinsOrProducts = 'selectBinsOrProducts',
  selectBinStatuses = 'selectBinStatuses',
  selectBinStatusesThatYouWantToExclude = 'selectBinStatusesThatYouWantToExclude',
  selectBinsThatYouWantToExclude = 'selectBinsThatYouWantToExclude',
  selectCompanyRolesThatShouldReceiveNotification = 'selectCompanyRolesThatShouldReceiveNotification',
  selectCsvUploadConfiguration = 'selectCsvUploadConfiguration',
  selectReportReceivers = 'selectReportReceivers',
  selectStockLocation = 'selectStockLocation',
  selectStockLocationRole = 'selectStockLocationRole',
  selectStockLocationRoles = 'selectStockLocationRoles',
  selectStockLocationRolesThatShouldReceiveNotification = 'selectStockLocationRolesThatShouldReceiveNotification',
  selectTaskAssignees = 'selectTaskAssignees',
  selectUsersThatShouldReceiveANotification = 'selectUsersThatShouldReceiveANotification',
  sellingPrice = 'sellingPrice',
  send = 'send',
  sendMailOnAssigned = 'sendMailOnAssigned',
  sendPushNotificationToAssignees = 'sendPushNotificationToAssignees',
  serial = 'serial',
  serialManaged = 'serialManaged',
  serialNumber = 'serialNumber',
  serialShippingContainerCode = 'serialShippingContainerCode',
  service = 'service',
  sessionStorageToggle = 'sessionStorageToggle',
  set = 'set',
  setCompanyRole = 'setCompanyRole',
  setDueDate = 'setDueDate',
  settings = 'settings',
  sevenDays = 'sevenDays',
  shipForDeliverForForwardToGlobalLocationNumber = 'shipForDeliverForForwardToGlobalLocationNumber',
  shipToDeliverISOcountryCode = 'shipToDeliverISOcountryCode',
  shipToDeliverToGlobalLocationNumber = 'shipToDeliverToGlobalLocationNumber',
  shipToDeliverToPostalCode = 'shipToDeliverToPostalCode',
  signIn = 'signIn',
  signOut = 'signOut',
  signUp = 'signUp',
  size = 'size',
  softwareVersion = 'softwareVersion',
  sourceBin = 'sourceBin',
  sourceNumber = 'sourceNumber',
  sourceStockLocation = 'sourceStockLocation',
  splitByOrder = 'splitByOrder',
  status = 'status',
  stock = 'stock',
  stockExportConfigurationSelectPlaceholder = 'stockExportConfigurationSelectPlaceholder',
  stockLocation = 'stockLocation',
  stockLocationAggregateTypeAllOfText = 'stockLocationAggregateTypeAllOfText',
  stockLocationAggregateTypeAllText = 'stockLocationAggregateTypeAllText',
  stockLocationAggregateTypeAnyOfText = 'stockLocationAggregateTypeAnyOfText',
  stockLocationAggregateTypeAnyText = 'stockLocationAggregateTypeAnyText',
  stockLocationCount = 'stockLocationCount',
  stockLocationIdentifier = 'stockLocationIdentifier',
  stockLocationLevel = 'stockLocationLevel',
  stockLocationMustBeDefinedToUploadBins = 'stockLocationMustBeDefinedToUploadBins',
  stockLocationName = 'stockLocationName',
  stockLocationRolesForInitialCountCannotBeStockLocationViewer = 'stockLocationRolesForInitialCountCannotBeStockLocationViewer',
  stockLocationWithIdentifierOnRowDoesNotExist = 'stockLocationWithIdentifierOnRowDoesNotExist',
  stockLocationWithIdentifierXNotFound = 'stockLocationWithIdentifierXNotFound',
  stockLocationWithNameAlreadyExists = 'stockLocationWithNameAlreadyExists',
  stockLocationWithNameXNotFound = 'stockLocationWithNameXNotFound',
  stockTriggers = 'stockTriggers',
  submit = 'submit',
  submitIdea = 'submitIdea',
  suffix = 'suffix',
  superUserWithEmailAlreadyExists = 'superUserWithEmailAlreadyExists',
  superUserWithUserIdAlreadyExists = 'superUserWithUserIdAlreadyExists',
  supervisor = 'supervisor',
  supplier = 'supplier',
  support = 'support',
  supportedFilesCanBeImagesVideoOrDocuments = 'supportedFilesCanBeImagesVideoOrDocuments',
  syncAddress = 'syncAddress',
  syncItems = 'syncItems',
  syncJobs = 'syncJobs',
  syncJournals = 'syncJournals',
  syncLocations = 'syncLocations',
  syncPurchaseOrders = 'syncPurchaseOrders',
  tableSettings = 'tableSettings',
  tapToViewThemInAppNotificationBody = 'tapToViewThemInAppNotificationBody',
  targetUsers = 'targetUsers',
  taskDueDateAlertText = 'taskDueDateAlertText',
  taskDueDateTriggerValueText = 'taskDueDateTriggerValueText',
  taskMetrics = 'taskMetrics',
  taskNumbers = 'taskNumbers',
  taskReportConfigurationWithNameAlreadyExists = 'taskReportConfigurationWithNameAlreadyExists',
  tasks = 'tasks',
  taskSettingsAlreadyExistsForThisEntity = 'taskSettingsAlreadyExistsForThisEntity',
  tasksStockUpdateFeatureToggle = 'tasksStockUpdateFeatureToggle',
  tasksTaskFeatureToggle = 'tasksTaskFeatureToggle',
  taskStatus = 'taskStatus',
  taskType = 'taskType',
  tellUsMore = 'tellUsMore',
  tellUsMoreFeature = 'tellUsMoreFeature',
  tellUsMoreIncident = 'tellUsMoreIncident',
  tenantId = 'tenantId',
  theme = 'theme',
  thirtyDays = 'thirtyDays',
  thismonth = 'thismonth',
  thisweek = 'thisweek',
  threeMonths = 'threeMonths',
  today = 'today',
  tokenAlreadyExists = 'tokenAlreadyExists',
  tokenForUserAlreadyExists = 'tokenForUserAlreadyExists',
  total = 'total',
  transactionInfo = 'transactionInfo',
  transactions = 'transactions',
  triggerCondition = 'triggerCondition',
  triggerEntityIds = 'triggerEntityIds',
  triggerEntityType = 'triggerEntityType',
  triggerInvalidAmountOfCustomFieldsProvided = 'triggerInvalidAmountOfCustomFieldsProvided',
  triggers = 'triggers',
  triggerTypes = 'triggerTypes',
  triggerTypeText = 'triggerTypeText',
  triggerUserText = 'triggerUserText',
  triggerValueText = 'triggerValueText',
  twelveMonths = 'twelveMonths',
  type = 'type',
  unableToAddLPNToProductIfProductMasterDataNotLPNManaged = 'unableToAddLPNToProductIfProductMasterDataNotLPNManaged',
  unableToAddProductWithQuantityOnRow = 'unableToAddProductWithQuantityOnRow',
  unableToAddSerialOnProductIfProductMasterDataNotSerialManaged = 'unableToAddSerialOnProductIfProductMasterDataNotSerialManaged',
  unableToAddUserWithInvalidCompanyRoleOnRowX = 'unableToAddUserWithInvalidCompanyRoleOnRowX',
  unableToAddUserWithoutEmailOnRowX = 'unableToAddUserWithoutEmailOnRowX',
  unableToCompleteOrderWithPendingItems = 'unableToCompleteOrderWithPendingItems',
  unableToCompleteTaskThatHasNotBeenStarted = 'unableToCompleteTaskThatHasNotBeenStarted',
  unableToCompleteTaskThatIsAlreadyCompleted = 'unableToCompleteTaskThatIsAlreadyCompleted',
  unableToCompleteTaskThatIsAlreadyProcessed = 'unableToCompleteTaskThatIsAlreadyProcessed',
  unableToCompleteTaskWhenThereAreProductsLeftToCount = 'unableToCompleteTaskWhenThereAreProductsLeftToCount',
  unableToCountTaskProductsWhenTaskHasNotBeenStarted = 'unableToCountTaskProductsWhenTaskHasNotBeenStarted',
  unableToCreateAllEnties = 'unableToCreateAllEnties',
  unableToCreateCompanyForDemo = 'unableToCreateCompanyForDemo',
  unableToCreateConfiguration = 'unableToCreateConfiguration',
  unableToCreateEntitiesForMultipleCompanies = 'unableToCreateEntitiesForMultipleCompanies',
  unableToCreateIntegrationSettings = 'unableToCreateIntegrationSettings',
  unableToCreateLotForNonLotManagedProductReferenceData = 'unableToCreateLotForNonLotManagedProductReferenceData',
  unableToCreateTaskWithoutAssignedUsers = 'unableToCreateTaskWithoutAssignedUsers',
  unableToCreateTaskWithoutDefiningProductReferenceDataBinsLotsOrContainers = 'unableToCreateTaskWithoutDefiningProductReferenceDataBinsLotsOrContainers',
  unableToCreateTaskWithoutUsersAssignedForRecount = 'unableToCreateTaskWithoutUsersAssignedForRecount',
  unableToDeleteAllEntities = 'unableToDeleteAllEntities',
  unableToExtractCustomerInformationFromPreviousJobs = 'unableToExtractCustomerInformationFromPreviousJobs',
  unableToFindAvailableBinStatus = 'unableToFindAvailableBinStatus',
  unableToFindBin = 'unableToFindBin',
  unableToFindCodeForUser = 'unableToFindCodeForUser',
  unableToFindCostCenter = 'unableToFindCostCenter',
  unableToFindDefaultBin = 'unableToFindDefaultBin',
  unableToFindDefaultStockLocation = 'unableToFindDefaultStockLocation',
  unableToFindDefaultStockLocationPurchaseOrder = 'unableToFindDefaultStockLocationPurchaseOrder',
  unableToFindDueDateCustomField = 'unableToFindDueDateCustomField',
  unableToFindExternalOrderId = 'unableToFindExternalOrderId',
  unableToFindJobForStockLocationIdentifier = 'unableToFindJobForStockLocationIdentifier',
  unableToFindJobForTransaction = 'unableToFindJobForTransaction',
  unableToFindJournalStockLocation = 'unableToFindJournalStockLocation',
  unableToFindJournalStockLocationXYZ = 'unableToFindJournalStockLocationXYZ',
  unableToFindOrderForLineId = 'unableToFindOrderForLineId',
  unableToFindPlant = 'unableToFindPlant',
  unableToFindProductMasterDataForLine = 'unableToFindProductMasterDataForLine',
  unableToFindTaskForCounting = 'unableToFindTaskForCounting',
  unableToFindTaskSettings = 'unableToFindTaskSettings',
  unableToFindTaskWithId = 'unableToFindTaskWithId',
  unableToFindUploadConfigWithId = 'unableToFindUploadConfigWithId',
  unableToFindUser = 'unableToFindUser',
  unableToFindUserWithEmail = 'unableToFindUserWithEmail',
  unableToGetAccessTokenFromBusinessCentral = 'unableToGetAccessTokenFromBusinessCentral',
  unableToGetEnvironmentFromSettings = 'unableToGetEnvironmentFromSettings',
  unableToGetTenantIdAndOrEnvironmentFromSettings = 'unableToGetTenantIdAndOrEnvironmentFromSettings',
  unableToGetTenantIdOrEnvironmentFromSettings = 'unableToGetTenantIdOrEnvironmentFromSettings',
  unableToParseDateForLotExpiry = 'unableToParseDateForLotExpiry',
  unableToParseHeaders = 'unableToParseHeaders',
  unableToRemoveBinStatusWhenBinsForItExist = 'unableToRemoveBinStatusWhenBinsForItExist',
  unableToRemoveLastAdministratorFromCompany = 'unableToRemoveLastAdministratorFromCompany',
  unableToRemoveLotWhenProductsExistForThatLot = 'unableToRemoveLotWhenProductsExistForThatLot',
  unableToRemoveProductReferenceDataWhenProductsExist = 'unableToRemoveProductReferenceDataWhenProductsExist',
  unableToStartTaskThatIsAlreadyCompleted = 'unableToStartTaskThatIsAlreadyCompleted',
  unableToStartTaskThatIsAlreadyInProgress = 'unableToStartTaskThatIsAlreadyInProgress',
  unableToStartTaskThatIsAlreadyProcessed = 'unableToStartTaskThatIsAlreadyProcessed',
  unableToUpdateAllEntities = 'unableToUpdateAllEntities',
  unableToUpdateEntitiesForMultipleCompanies = 'unableToUpdateEntitiesForMultipleCompanies',
  unableToUpdateStockFeatureToggleDisabled = 'unableToUpdateStockFeatureToggleDisabled',
  unableToUpdateStockWhenTaskIsNotComplete = 'unableToUpdateStockWhenTaskIsNotComplete',
  unableToUpdateTaskWithoutAssignedUsers = 'unableToUpdateTaskWithoutAssignedUsers',
  unableToUploadCsvWithoutConfig = 'unableToUploadCsvWithoutConfig',
  unableToUserConfigForUploadedCsv = 'unableToUserConfigForUploadedCsv',
  unauthorizedUser = 'unauthorizedUser',
  uneceMeatCarcassesAndCutsClassification = 'uneceMeatCarcassesAndCutsClassification',
  unit = 'unit',
  unitOfMeasure = 'unitOfMeasure',
  unknown = 'unknown',
  unknownBin = 'unknownBin',
  unknownLot = 'unknownLot',
  unknownProduct = 'unknownProduct',
  unknownProductNumber = 'unknownProductNumber',
  unknownProductReference = 'unknownProductReference',
  unknownStockLocation = 'unknownStockLocation',
  unknownUser = 'unknownUser',
  update = 'update',
  updateBarcodeConfiguration = 'updateBarcodeConfiguration',
  updateBin = 'updateBin',
  updateBinStatus = 'updateBinStatus',
  updateCustomField = 'updateCustomField',
  updatedAt = 'updatedAt',
  updatedBy = 'updatedBy',
  updateProfile = 'updateProfile',
  updateScanRule = 'updateScanRule',
  updateShippingLocation = 'updateShippingLocation',
  updateStock = 'updateStock',
  updateStockLocationRole = 'updateStockLocationRole',
  updateSuperUser = 'updateSuperUser',
  updateUserDetails = 'updateUserDetails',
  updatingYourProfileWillSignYouOut = 'updatingYourProfileWillSignYouOut',
  uploadConfigurationNotDefined = 'uploadConfigurationNotDefined',
  uploadConfigurationNotFound = 'uploadConfigurationNotFound',
  uploadConfigurationWithNameAlreadyExists = 'uploadConfigurationWithNameAlreadyExists',
  uploadedAt = 'uploadedAt',
  uploadedBy = 'uploadedBy',
  uploadFileHasDuplicateHeaderName = 'uploadFileHasDuplicateHeaderName',
  uploadFileHasMissingRequiredFields = 'uploadFileHasMissingRequiredFields',
  uploadProductImagesInTheFilesAndAssetsSection = 'uploadProductImagesInTheFilesAndAssetsSection',
  urlInput = 'urlInput',
  user = 'user',
  userAlreadyHasRoleForStockLocation = 'userAlreadyHasRoleForStockLocation',
  userAlreadyPartOfCompany = 'userAlreadyPartOfCompany',
  userAssignedToBothInitialCountAndRecount = 'userAssignedToBothInitialCountAndRecount',
  userDetailWithUserIdAlreadyExists = 'userDetailWithUserIdAlreadyExists',
  userDoesNotHaveAnyAssignedStockLocations = 'userDoesNotHaveAnyAssignedStockLocations',
  userInviteEmail = 'userInviteEmail',
  userNotFound = 'userNotFound',
  userWithEmailAlreadyExists = 'userWithEmailAlreadyExists',
  validateBarcodes = 'validateBarcodes',
  validateQuantity = 'validateQuantity',
  valueForCustomFieldXNotValidBooleanGotY = 'valueForCustomFieldXNotValidBooleanGotY',
  valueForCustomFieldXNotValidDateGotY = 'valueForCustomFieldXNotValidDateGotY',
  valueForCustomFieldXNotValidListValueGotYValidZ = 'valueForCustomFieldXNotValidListValueGotYValidZ',
  valueName = 'valueName',
  variableCountOfItems = 'variableCountOfItems',
  verifyDeleteCompany = 'verifyDeleteCompany',
  verifyDeleteFiles = 'verifyDeleteFiles',
  verifyDeleteImportConfiguration = 'verifyDeleteImportConfiguration',
  verifyDeleteOrders = 'verifyDeleteOrders',
  verifyDeleteProductMasterData = 'verifyDeleteProductMasterData',
  verifyDeleteProductMasterDataExportConfiguration = 'verifyDeleteProductMasterDataExportConfiguration',
  verifyDeleteStockLocation = 'verifyDeleteStockLocation',
  verifyDeleteTasks = 'verifyDeleteTasks',
  verifyDeleteTriggers = 'verifyDeleteTriggers',
  verifyEmail = 'verifyEmail',
  viewer = 'viewer',
  vnt = 'vnt',
  volume = 'volume',
  width = 'width',
  wrongClientIdentifier = 'wrongClientIdentifier',
  yes = 'yes',
  yesterday = 'yesterday',
  device = 'device',

  singular = 'singular',
  plural = 'plural',
  uppercase = 'uppercase',
  lowercase = 'lowercase',
  lot = 'lot',
  manageType = 'manageType',
  fileTypeCategory = 'fileTypeCategory',
  fileType = 'fileType',
  file = 'file',
  updateStockRole = 'updateStockRole',
  stockLocationRole = 'stockLocationRole',
  changeCompany = 'changeCompany',
  drop = 'drop',
  click = 'click',
  dragAndDrop = 'dragAndDrop',
  initialCounter = 'initialCounter',
  recounter = 'recounter',
  shipped = 'shipped',
  arrived = 'arrived',
  failed = 'failed',
  disabled = 'disabled',
  verifyDeleteTaskExportConfiguration = 'verifyDeleteTaskExportConfiguration',
  noStockCycleCount = 'noStockCycleCount',
  defaultConfiguration = 'defaultConfiguration',
  leave = 'leave',
  firstAndLastName = 'firstAndLastName',
  stockAdjustment = 'stockAdjustment',
  originalQuantity = 'originalQuantity',
  newQuantity = 'newQuantity',
  move = 'move',
  regexInput = 'regexInput',
  pleaseEnterCompanyNameToConfirm = 'pleaseEnterCompanyNameToConfirm',
  verifyLeaveCompany = 'verifyLeaveCompany',
  verifyRemoveUserFromCompany = 'verifyRemoveUserFromCompany',
  addAdditionalProductMasterDataExportField = 'addAdditionalProductMasterDataExportField',
  orderTransaction = 'orderTransaction',
  triggerStockLocationText = 'triggerStockLocationText',
  filterStockLocationBins = 'filterStockLocationBins',
  addProducts = 'addProducts',
  ignoreValidation = 'ignoreValidation',
  scanRuleField = 'scanRuleField',
  scanRuleStringValue = 'scanRuleStringValue',
  enterStockLocationName = 'enterStockLocationName',
  somethingUnexpectedHappened = 'somethingUnexpectedHappened',
  meter = 'meter',
  gram = 'gram',
  liter = 'liter',
  hide = 'hide',
  show = 'show',
  reorderUserRule = 'reorderUserRule',
  licenseAgreement = 'licenseAgreement',
  privacyPolicy = 'privacyPolicy',
  termsOfUse = 'termsOfUse',
  veryifyDeleteReorderRules = 'veryifyDeleteReorderRules',
  appliesToAllLocations = 'appliesToAllLocations',
  ruleStockLocationText = 'ruleStockLocationText',
  noRulesOrUsersSelected = 'noRulesOrUsersSelected',
  unknownBinStatus = 'unknownBinStatus',
  ruleProductText = 'ruleProductText',
  enterNameOfReorderUserRule = 'enterNameOfReorderUserRule',
  veryifyDeleteReorderUserRules = 'veryifyDeleteReorderUserRules',
  reorderQuantity = 'reorderQuantity',
  replenishQuantity = 'replenishQuantity',

  select = 'select',
  showing = 'showing',
  untranslatedOnly = 'untranslatedOnly',
  number = 'number',
  directOutbound = 'directOutbound',
  expectedReceiptDate = 'expectedReceiptDate',
  expectedDeliveryDate = 'expectedDeliveryDate',
}
