import React, { useContext, useState } from 'react';
import { t } from '../../../../types/translation/Translator';
import { useMutation } from '@apollo/client';
import { OrderContext } from '../../../../context/OrderContext';
import { DeleteOrderResponse, DeleteOrderVariables, OrderMutations } from '../../../../graphql/order.graphql';
import { DeleteOrderInput } from '../../../../types/order';

import DeleteModal from '../../../../VentoryUI/components/common/Modal/DeleteModal';

interface DeleteOrderModalProps {
  open: boolean;
  onClose: () => void;
  ids: Set<string>;
  setSelected: (selected: Set<string>) => void;
}

export default function DeleteOrderModal({ open, onClose, ids, setSelected }: DeleteOrderModalProps) {
  const { orders, setOrders } = useContext(OrderContext);

  const [error, setError] = useState<string>('');

  const [remove, { loading }] = useMutation<DeleteOrderResponse, DeleteOrderVariables>(OrderMutations.remove, {
    onCompleted: res => {
      res.deleteOrder.forEach(order => orders.delete(order.id));
      setOrders(new Map(orders));
      setSelected(new Set());
      onClose();
    },
    onError: err => setError(err.message),
  });

  const handleDelete = async () => {
    try {
      const deletedOrders: DeleteOrderInput[] = [];
      for (const id of ids) {
        const order = orders.get(id);
        if (order) deletedOrders.push(order.forDelete());
      }

      let deleted = 0;
      do {
        await remove({
          variables: {
            orders: deletedOrders.slice(deleted, deleted + 3000),
          },
        });
        deleted += 3000;
      } while (deleted < deletedOrders.length);
    } catch (e) {
      setError(String(e));
    }
  };

  return (
    <DeleteModal
      open={open}
      error={error}
      onClose={onClose}
      onConfirm={handleDelete}
      text={t().verifyDeleteOrders.singular.label}
    />
  );
}
