import { useQuery } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { CompanyContext } from '../../context/CompanyContext';
import { Bin } from '../../types/bin';
import { BinQueries, GetBinsResponse, GetBinsVariables } from '../../graphql/bin.graphql';
import { BinContext, BinContextProps } from '../../context/BinContext';
import {
  GetProductMasterDataResponse,
  GetProductMasterDataVariables,
  ProductMasterDataQueries,
} from '../../graphql/productMasterData.graphql';
import { ProductMasterDataContext, ProductMasterDataContextProps } from '../../context/ProductMasterDataContext';
import { ProductMasterData } from '../../types/productMasterData';
import { ProductContext, ProductContextProps } from '../../context/ProductContext';
import { Product } from '../../types/product';
import { GetProductResponse, GetProductVariables, ProductQueries } from '../../graphql/product.graphql';
import { GetLotsResponse, GetLotsVariables, LotQueries } from '../../graphql/lot.graphql';
import { Lot } from '../../types/lot';
import { LotContext, LotContextProps } from '../../context/LotContext';
import {
  GetProductTransactionsResponse,
  GetProductTransactionsVariables,
  ProductTransactionQueries,
} from '../../graphql/productTransaction.graphql';
import { ProductTransaction } from '../../types/productTransaction';
import { ProductTransactionContext, ProductTransactionContextProps } from '../../context/ProductTransactionContext';
import { GetTasksResponse, GetTasksVariables, TaskQueries } from '../../graphql/task.graphql';
import { Task } from '../../types/task';
import { TaskContext, TaskContextProps } from '../../context/TaskContext';
import { Route, Routes } from 'react-router-dom';
import HomeScreen from './HomeScreen';
import { CompanyRoleAssignmentContext } from '../../context/CompanyRoleAssignmentContext';
import { UserContext } from '../../context/UserContext';
import { CompanyInviteState, CompanyRole } from '../../types/companyRoleAssignment';
import AcceptCompanyInviteScreen from '../Authentication/AcceptCompanyInviteScreen';
import NotificationHandler from '../../subscriptions/NotificationHandler';
import { CommentQueries, GetCommentsResponse, GetCommentsVariables } from '../../graphql/comment.graphql';
import { Comment } from '../../types/comment';
import { CommentContext, CommentContextProps } from '../../context/CommentContext';
import { ScanRule } from '../../types/scanRule';
import { ScanRuleContext, ScanRuleContextProps } from '../../context/ScanRuleContext';
import { GetScanRuleResponse, GetScanRuleVariables, ScanRuleQueries } from '../../graphql/scanRule.graphql';
import { Contact } from '../../types/contact';
import { ContactQueries, GetContactsResponse, GetContactsVariables } from '../../graphql/contact.graphql';
import { ContactContext, ContactContextProps } from '../../context/ContactContext';
import { Order } from '../../types/order';
import { GetOrdersResponse, GetOrdersVariables, OrderQueries } from '../../graphql/order.graphql';
import { OrderContext, OrderContextProps } from '../../context/OrderContext';
import {
  DEV_BEACON_ID,
  NETWORK_RAIL_BEACON_ID,
  PRODUCTION_BEACON_ID,
  STAGING_BEACON_ID,
  beaconInfo,
  destroyBeacon,
  identifyBeacon,
  initBeacon,
  setBeaconColor,
  setBeaconConfig,
} from '../Support/util/HelpScout';
import HelpScoutBadge from '../Support/HelpScoutBadge';
import { AlertContext, AlertContextProps } from '../../context/AlertContext';
import { Alert } from '../../types/alert';
import { AlertQueries, GetAlertResponse, GetAlertVariables } from '../../graphql/alert.graphql';
import { GetTriggerResponse, GetTriggerVariables, TriggerQueries } from '../../graphql/trigger.graphql';
import { Trigger } from '../../types/trigger';
import { TriggerContext, TriggerContextProps } from '../../context/TriggerContext';
import { VentorySubscription } from '../../types/billing';
import {
  BillingQueries,
  GetVentorySubscriptionResponse,
  GetVentorySubscriptionVariables,
} from '../../graphql/billing.graphql';
import { BillingContext, BillingContextProps } from '../../context/BillingContext';
import { TaskExportConfiguration } from '../../types/taskExportConfiguration';
import {
  GetTaskExportConfigurationsResponse,
  GetTaskExportConfigurationsVariables,
  TaskExportConfigurationQueries,
} from '../../graphql/taskExportConfiguration.graphql';
import {
  TaskExportConfigurationContext,
  TaskExportConfigurationContextProps,
} from '../../context/TaskExportConfigurationContext';
import { CustomFieldContext, CustomFieldContextProps } from '../../context/CustomFieldContext';
import { CustomField } from '../../types/customField';
import { CustomFieldQueries, GetCustomFieldResponse, GetCustomFieldVariables } from '../../graphql/customField.graphql';
import { OrderExportConfiguration } from '../../types/orderExportConfiguration';
import {
  OrderExportConfigurationContext,
  OrderExportConfigurationContextProps,
} from '../../context/OrderExportConfigurationContext';
import {
  GetOrderExportConfigurationsResponse,
  GetOrderExportConfigurationsVariables,
  OrderExportConfigurationQueries,
} from '../../graphql/orderExportConfiguration.graphql';
import {
  GetOrderTableSettingsResponse,
  GetOrderTableSettingsVariables,
  OrderTableSettingsQueries,
} from '../../graphql/orderTableSettings.graphql';
import { OrderTableSettings } from '../../types/orderTableSettings';
import { OrderTableSettingsContext, OrderTableSettingsContextProps } from '../../context/OrderTableSettingsContext';
import { IntegrationSettings } from '../../types/integrationSettings';
import {
  GetIntegrationSettingsResponse,
  GetIntegrationSettingsVariables,
  IntegrationSettingsQueries,
} from '../../graphql/integrationSettings.graphql';
import { IntegrationContext, IntegrationContextProps } from '../../context/IntegrationContext';
import { CsvUploadConfigurationContext } from '../../context/CsvUploadConfigurationContext';
import { CsvUploadConfiguration } from '../../types/csvUploadConfiguration';
import {
  CsvUploadConfigurationQueries,
  GetCsvUploadConfigurationsResponse,
  GetCsvUploadConfigurationsVariables,
} from '../../graphql/csvUploadConfiguration';
import {
  ProductExportConfigurationContext,
  ProductExportConfigurationContextProps,
} from '../../context/ProductExportConfigurationContext';
import { ProductExportConfiguration } from '../../types/productExportConfiguration';
import {
  GetProductExportConfigurationsResponse,
  GetProductExportConfigurationsVariables,
  ProductExportConfigurationQueries,
} from '../../graphql/productExportConfiguration.graphql';
import {
  GetProductTransactionExportConfigurationsResponse,
  GetProductTransactionExportConfigurationsVariables,
  ProductTransactionExportConfigurationQueries,
} from '../../graphql/productTransactionExportConfiguration.graphql';
import { ProductTransactionExportConfiguration } from '../../types/productTransactionExportConfiguration';
import {
  ProductTransactionExportConfigurationContext,
  ProductTransactionExportConfigurationContextProps,
} from '../../context/ProductTransactionExportConfigurationContext';
import { TaskSettingsContext, TaskSettingsContextProps } from '../../context/TaskSettingsContext';
import { TaskSettings } from '../../types/taskSettings';
import {
  GetTaskSettingsResponse,
  GetTaskSettingsVariables,
  TaskSettingsQueries,
} from '../../graphql/taskSettings.graphql';
import { DashboardContext, DashboardContextProps } from '../../context/DashboardContext';
import { ChartData } from '../Dashboard/Graphs/GraphContainer';
import {
  ProductTransactionTableSettingsContext,
  ProductTransactionTableSettingsContextProps,
} from '../../context/ProductTransactionTableSettingsContext';
import { ProductTransactionTableSettings } from '../../types/productTransactionTableSettings';
import {
  GetProductTransactionTableSettingsResponse,
  GetProductTransactionTableSettingsVariables,
  ProductTransactionTableSettingsQueries,
} from '../../graphql/productTransactionTableSettings.graphql';
import { AuthenticationSettings } from '../../types/authenticationSettings';
import { AuthenticationSettingsContext, AuthenticationSettingsContextProps } from '../../context/UserSettingsContext';
import {
  AuthenticationSettingsQueries,
  GetAuthenticationSettingsResponse,
  GetAuthenticationSettingsVariables,
} from '../../graphql/authenticationSettings.graphql';
import {
  GetReorderRulesResponse,
  GetReorderRulesVariables,
  ReorderRuleQueries,
} from '../../graphql/reorderRule.graphql';
import { ReorderRule } from '../../types/reorderRule';
import { ReorderRuleContext, ReorderRuleContextProps } from '../../context/ReorderRuleContext';
import { ReorderUserRule } from '../../types/reorderUserRule';
import {
  GetReorderUserRulesResponse,
  GetReorderUserRulesVariables,
  ReorderUserRuleQueries,
} from '../../graphql/reorderUserRule.graphql';
import { ReorderUserRuleContext, ReorderUserRuleContextProps } from '../../context/ReorderUserRuleContext';
import { ProductMasterDataExportConfiguration } from '../../types/productMasterDataExportConfiguration';
import {
  GetProductMasterDataExportConfigurationsResponse,
  GetProductMasterDataExportConfigurationsVariables,
  ProductMasterDataExportConfigurationQueries,
} from '../../graphql/productMasterDataExportConfiguration.graphql';
import {
  ProductMasterDataExportConfigurationContext,
  ProductMasterDataExportConfigurationContextProps,
} from '../../context/ProductMasterDataExportConfigurationContext';
import { FileContext, FileContextProps } from '../../context/FileContext';
import { FileEntity, FileWithSas } from '../../types/file';
import { FileQueries, GetFilesResponse, GetFilesVariables } from '../../graphql/file.graphql';
import { Colors } from '../../VentoryUI/util/color.util';
import { v4 } from 'uuid';
import { TranslationContext } from '../../context/TranslationContext';
import { CustomTranslationContext } from '../../context/CustomTranslationContext';

export default function ContentPage() {
  const { currentCompany } = useContext(CompanyContext);

  const { currentUser } = useContext(UserContext);
  const { companyRoles } = useContext(CompanyRoleAssignmentContext);

  const [productMasterData, setProductMasterData] = useState<Map<string, ProductMasterData>>(new Map());
  const [lots, setLots] = useState<Map<string, Lot>>(new Map());
  const [bins, setBins] = useState<Map<string, Bin>>(new Map());
  const [products, setProducts] = useState<Map<string, Map<string, Product>>>(new Map());
  const [productTransactions, setProductTransactions] = useState<Map<string, ProductTransaction>>(new Map());
  const [tasks, setTasks] = useState<Map<string, Task>>(new Map());
  const [comments, setComments] = useState<Map<string, Map<string, Comment>>>(new Map());
  const [scanRules, setScanRules] = useState<Map<string, ScanRule>>(new Map());
  const [contacts, setContacts] = useState<Map<string, Contact>>(new Map());
  const [orders, setOrders] = useState<Map<string, Order>>(new Map());
  const [taskExportConfigurations, setTaskExportConfigurations] = useState<Map<string, TaskExportConfiguration>>(
    new Map(),
  );
  const [orderExportConfigurations, setOrderExportConfigurations] = useState<Map<string, OrderExportConfiguration>>(
    new Map(),
  );
  const [orderTableSettings, setOrderTableSettings] = useState<Map<string, OrderTableSettings>>(new Map());
  const [alerts, setAlerts] = useState<Map<string, Alert>>(new Map());
  const [triggers, setTriggers] = useState<Map<string, Trigger>>(new Map());
  const [subscription, setSubscription] = useState<VentorySubscription>(
    new VentorySubscription({ companyId: currentCompany.id }),
  );
  const [customFields, setCustomFields] = useState<Map<string, CustomField>>(new Map());
  const [integrationSettings, setIntegrationSettings] = useState<Map<string, IntegrationSettings>>(new Map());
  const [csvUploadConfigurations, setCsvUploadConfigurations] = useState<Map<string, CsvUploadConfiguration>>(
    new Map(),
  );
  const [taskSettings, setTaskSettings] = useState<Map<string, TaskSettings>>(new Map());
  const [authenticationSettings, setAuthenticationSettings] = useState<AuthenticationSettings | undefined>(undefined);

  const [productExportConfigurations, setProductExportConfigurations] = useState<
    Map<string, ProductExportConfiguration>
  >(new Map());
  const [productTransactionExportConfigurations, setProductTransactionExportConfigurations] = useState<
    Map<string, ProductTransactionExportConfiguration>
  >(new Map());
  const [productTransactionTableSettings, setProductTransactionTableSettings] = useState<
    Map<string, ProductTransactionTableSettings>
  >(new Map());
  const [reorderRules, setReorderRules] = useState<Map<string, ReorderRule>>(new Map());
  const [groupedReorderRules, setGroupedReorderRules] = useState<Map<string, ReorderRule[]>>(new Map());
  const [reorderUserRules, setReorderUserRules] = useState<Map<string, ReorderUserRule>>(new Map());
  const [groupedReorderUserRules, setGroupedReorderUserRules] = useState<Map<string, ReorderUserRule[]>>(new Map());
  const [productMasterDataExportConfigurations, setProductMasterDataExportConfigurations] = useState<
    Map<string, ProductMasterDataExportConfiguration>
  >(new Map());
  const [files, setFiles] = useState(new Map<string, FileEntity>());
  const [fileCache, setFileCache] = useState(new Map<string, FileWithSas>());

  const [productMasterDataLoading, setProductMasterDataLoading] = useState<boolean>(true);
  const [productTransactionsLoading, setProductTransactionsLoading] = useState<boolean>(true);
  const [scanRulesLoading, setScanRulesLoading] = useState<boolean>(true);
  const [contactsLoading, setContactsLoading] = useState<boolean>(true);
  const [binsLoading, setBinsLoading] = useState<boolean>(true);
  const [ordersLoading, setOrdersLoading] = useState<boolean>(true);
  const [taskExportConfigurationsLoading, setTaskExportConfigurationsLoading] = useState<boolean>(true);
  const [orderExportConfigurationsLoading, setOrderExportConfigurationsLoading] = useState<boolean>(true);
  const [orderTableSettingsLoading, setOrderTableSettingsLoading] = useState<boolean>(true);
  const [alertsLoading, setAlertsLoading] = useState<boolean>(true);
  const [triggersLoading, setTriggersLoading] = useState<boolean>(true);
  const [productsLoading, setProductsLoading] = useState<boolean>(true);
  const [lotsLoading, setLotsLoading] = useState<boolean>(true);
  const [tasksLoading, setTasksLoading] = useState<boolean>(true);
  const [customFieldsLoading, setCustomFieldsLoading] = useState<boolean>(true);
  const [csvUploadConfigurationsLoading, setCsvUploadConfigurationsLoading] = useState<boolean>(true);
  const [productExportConfigurationsLoading, setProductExportConfigurationsLoading] = useState<boolean>(true);
  const [productTransactionExportConfigurationsLoading, setProductTransactionExportConfigurationsLoading] =
    useState<boolean>(true);
  const [taskSettingsLoading, setTaskSettingsLoading] = useState<boolean>(true);
  const [userSettingsLoading, setUserSettingsLoading] = useState<boolean>(true);
  const [dashboardCache, setDashboardCache] = useState<Map<string, { filteredChartData: ChartData[]; total: number }>>(
    new Map(),
  );
  const [productTransactionTableSettingsLoading, setProductTransactionTableSettingsLoading] = useState<boolean>(true);
  const [reorderRulesLoading, setReorderRulesLoading] = useState<boolean>(true);
  const [reorderUserRulesLoading, setReorderUserRulesLoading] = useState<boolean>(true);
  const [productMasterDataExportConfigurationsLoading, setProductMasterDataExportConfigurationsLoading] =
    useState<boolean>(true);
  const [filesLoading, setFilesLoading] = useState(true);

  const { refetch: refetchFiles } = useQuery<GetFilesResponse, GetFilesVariables>(FileQueries.get, {
    variables: { companyId: currentCompany.id, page: 0 },
    onError: () => setFilesLoading(false),
    onCompleted: async response => {
      response.files.data.forEach(file => files.set(file.id, new FileEntity(file)));
      setFiles(new Map(files));
      if (response.files.hasNext === true) {
        await refetchFiles({ companyId: currentCompany.id, page: ++response.files.page });
      } else {
        setFilesLoading(false);
      }
    },
  });

  const { refetch: refetchProductMasterData } = useQuery<GetProductMasterDataResponse, GetProductMasterDataVariables>(
    ProductMasterDataQueries.get,
    {
      variables: { companyId: currentCompany.companyId },
      onCompleted: async res => {
        res.productMasterDataForCompany.data.forEach(pmd => productMasterData.set(pmd.id, new ProductMasterData(pmd)));
        setProductMasterData(new Map(productMasterData));
        if (res.productMasterDataForCompany.hasNext === true) {
          refetchProductMasterData({
            companyId: currentCompany.companyId,
            page: ++res.productMasterDataForCompany.page,
          });
        } else {
          setProductMasterDataLoading(false);
        }
      },
    },
  );

  const { refetch: refetchAlerts } = useQuery<GetAlertResponse, GetAlertVariables>(AlertQueries.get, {
    variables: { companyId: currentCompany.companyId },
    onCompleted: async res => {
      res.alerts.data.forEach((alert: Alert) => alerts.set(alert.id, new Alert(alert)));
      setAlerts(new Map(alerts));

      if (res.alerts.hasNext === true) {
        refetchAlerts({
          companyId: currentCompany.companyId,
          page: ++res.alerts.page,
        });
      } else {
        setAlertsLoading(false);
      }
    },
  });

  const { refetch: refetchCustomFields } = useQuery<GetCustomFieldResponse, GetCustomFieldVariables>(
    CustomFieldQueries.get,
    {
      variables: { companyId: currentCompany.companyId },
      onCompleted: async res => {
        res.customFields.data.forEach(cf => customFields.set(cf.id, new CustomField(cf)));
        setCustomFields(new Map(customFields));
        if (res.customFields.hasNext === true) {
          refetchCustomFields({
            companyId: currentCompany.companyId,
            page: ++res.customFields.page,
          });
        } else {
          setCustomFieldsLoading(false);
        }
      },
    },
  );

  const { loading: integrationSettingsLoading, refetch: refetchIntegrationSettings } = useQuery<
    GetIntegrationSettingsResponse,
    GetIntegrationSettingsVariables
  >(IntegrationSettingsQueries.get, {
    variables: { companyId: currentCompany.companyId },
    onCompleted: async res => {
      res.integrationSettings.forEach(is => integrationSettings.set(is.id, new IntegrationSettings(is)));
      setIntegrationSettings(new Map(integrationSettings));
    },
  });

  const { refetch: refetchTriggers } = useQuery<GetTriggerResponse, GetTriggerVariables>(TriggerQueries.get, {
    variables: { companyId: currentCompany.companyId },
    onCompleted: async res => {
      res.triggers.data.forEach(trigger => triggers.set(trigger.id, new Trigger(trigger)));
      setTriggers(new Map(triggers));
      if (res.triggers.hasNext === true) {
        refetchTriggers({
          companyId: currentCompany.companyId,
          page: ++res.triggers.page,
        });
      } else {
        setTriggersLoading(false);
      }
    },
  });

  const { refetch: refetchContacts } = useQuery<GetContactsResponse, GetContactsVariables>(ContactQueries.get, {
    variables: { companyId: currentCompany.companyId },
    onCompleted: async res => {
      res.contacts.data.forEach(contact => contacts.set(contact.id, new Contact(contact)));
      setContacts(new Map(contacts));
      if (res.contacts.hasNext === true) {
        refetchContacts({
          companyId: currentCompany.companyId,
          page: ++res.contacts.page,
        });
      } else {
        setContactsLoading(false);
      }
    },
  });

  const { refetch: refetchTaskExportConfigurations } = useQuery<
    GetTaskExportConfigurationsResponse,
    GetTaskExportConfigurationsVariables
  >(TaskExportConfigurationQueries.get, {
    variables: { companyId: currentCompany.companyId },
    onCompleted: async res => {
      res.taskExportConfigurations.data.forEach(config =>
        taskExportConfigurations.set(config.id, new TaskExportConfiguration(config)),
      );
      setTaskExportConfigurations(new Map(taskExportConfigurations));
      if (res.taskExportConfigurations.hasNext === true) {
        refetchTaskExportConfigurations({
          companyId: currentCompany.companyId,
          page: ++res.taskExportConfigurations.page,
        });
      } else {
        setTaskExportConfigurationsLoading(false);
      }
    },
  });

  const { refetch: refetchOrderExportConfigurations } = useQuery<
    GetOrderExportConfigurationsResponse,
    GetOrderExportConfigurationsVariables
  >(OrderExportConfigurationQueries.get, {
    variables: { companyId: currentCompany.companyId },
    onCompleted: async res => {
      res.orderExportConfigurations.data.forEach(config =>
        orderExportConfigurations.set(config.id, new OrderExportConfiguration(config)),
      );
      setOrderExportConfigurations(new Map(orderExportConfigurations));
      if (res.orderExportConfigurations.hasNext === true) {
        refetchOrderExportConfigurations({
          companyId: currentCompany.companyId,
          page: ++res.orderExportConfigurations.page,
        });
      } else {
        setOrderExportConfigurationsLoading(false);
      }
    },
  });

  const { refetch: refetchOrderTableSettings } = useQuery<
    GetOrderTableSettingsResponse,
    GetOrderTableSettingsVariables
  >(OrderTableSettingsQueries.get, {
    variables: { companyId: currentCompany.companyId },
    onCompleted: async res => {
      res.orderTableSettings.data.forEach(setting =>
        orderTableSettings.set(setting.id, new OrderTableSettings(setting)),
      );
      setOrderTableSettings(new Map(orderTableSettings));
      if (res.orderTableSettings.hasNext === true) {
        refetchOrderTableSettings({
          companyId: currentCompany.companyId,
          page: ++res.orderTableSettings.page,
        });
      } else {
        setOrderTableSettingsLoading(false);
      }
    },
  });

  const { refetch: refetchOrders } = useQuery<GetOrdersResponse, GetOrdersVariables>(OrderQueries.get, {
    variables: { companyId: currentCompany.companyId },
    onCompleted: async res => {
      res.orders.data.forEach(order => orders.set(order.id, new Order(order)));
      setOrders(new Map(orders));
      if (res.orders.hasNext === true) {
        refetchOrders({
          companyId: currentCompany.companyId,
          page: ++res.orders.page,
        });
      } else {
        setOrdersLoading(false);
      }
    },
  });

  const { refetch: refetchScanRules } = useQuery<GetScanRuleResponse, GetScanRuleVariables>(ScanRuleQueries.get, {
    variables: { companyId: currentCompany.companyId },
    onCompleted: async res => {
      res.scanRules.forEach(rule => scanRules.set(rule.id, new ScanRule(rule)));
      setScanRules(new Map(scanRules));
      setScanRulesLoading(false);
    },
  });

  const { loading: commentsLoading, refetch: refetchComments } = useQuery<GetCommentsResponse, GetCommentsVariables>(
    CommentQueries.get,
    {
      variables: { companyId: currentCompany.companyId },
      onCompleted: async res => {
        res.comments.data.forEach(comment => {
          const index = comment.parentId || comment.id;
          comments.set(index, (comments.get(index) || new Map()).set(comment.id, new Comment(comment)));
        });
        setComments(new Map(comments));
      },
    },
  );

  const { loading: billingLoading } = useQuery<GetVentorySubscriptionResponse, GetVentorySubscriptionVariables>(
    BillingQueries.get,
    {
      variables: { companyId: currentCompany.id },
      onCompleted: async res => {
        setSubscription(new VentorySubscription(res.subscription || { companyId: currentCompany.id }));
      },
    },
  );

  const { refetch: refetchLots } = useQuery<GetLotsResponse, GetLotsVariables>(LotQueries.get, {
    variables: { companyId: currentCompany.companyId },
    onCompleted: async res => {
      res.lots.data.forEach(lot => lots.set(lot.id, new Lot(lot)));
      setLots(new Map(lots));
      if (res.lots.hasNext === true) {
        refetchLots({ companyId: currentCompany.companyId, page: ++res.lots.page });
      } else {
        setLotsLoading(true);
      }
    },
  });

  const { refetch: refetchTasks } = useQuery<GetTasksResponse, GetTasksVariables>(TaskQueries.get, {
    variables: { companyId: currentCompany.companyId },
    onCompleted: async res => {
      res.tasks.data.forEach(task => tasks.set(task.id, new Task(task)));
      setTasks(new Map(tasks));
      if (res.tasks.hasNext === true) {
        refetchTasks({ companyId: currentCompany.companyId, page: ++res.tasks.page });
      } else {
        setTasksLoading(false);
      }
    },
  });

  const { refetch: refetchBins } = useQuery<GetBinsResponse, GetBinsVariables>(BinQueries.get, {
    variables: { companyId: currentCompany.companyId },
    onCompleted: async res => {
      res.bins.data.forEach(bin => bins.set(bin.id, new Bin(bin)));
      setBins(new Map(bins));
      if (res.bins.hasNext === true) {
        refetchBins({
          companyId: currentCompany.companyId,
          page: ++res.bins.page,
        });
      } else {
        setBinsLoading(false);
      }
    },
  });

  const { refetch: refetchProducts } = useQuery<GetProductResponse, GetProductVariables>(ProductQueries.get, {
    variables: { companyId: currentCompany.companyId },
    onCompleted: async res => {
      res.products.data.forEach(product =>
        products.set(
          product.productMasterDataId,
          (products.get(product.productMasterDataId) || new Map()).set(product.id, product),
        ),
      );
      setProducts(new Map(products));
      if (res.products.hasNext === true) {
        refetchProducts({ companyId: currentCompany.companyId, page: ++res.products.page });
      } else {
        setProductsLoading(false);
      }
    },
  });

  const skipProductTransactions = !currentCompany.settings.featureToggles.productTransactions.productTransactions;

  const { refetch: refetchProductTransactions } = useQuery<
    GetProductTransactionsResponse,
    GetProductTransactionsVariables
  >(ProductTransactionQueries.get, {
    variables: { companyId: currentCompany.companyId, batchSize: 1000 },
    skip: skipProductTransactions,
    onCompleted: async res => {
      res.productTransactions.data.forEach(pt => productTransactions.set(pt.id, new ProductTransaction(pt)));
      setProductTransactions(new Map(productTransactions));
      if (res.productTransactions.hasNext === true) {
        await refetchProductTransactions({
          companyId: currentCompany.companyId,
          page: ++res.productTransactions.page,
          batchSize: 1000,
        });
      } else {
        setProductTransactionsLoading(false);
      }
    },
  });

  const { refetch: refetchCsvUploadConfigurations } = useQuery<
    GetCsvUploadConfigurationsResponse,
    GetCsvUploadConfigurationsVariables
  >(CsvUploadConfigurationQueries.get, {
    variables: { companyId: currentCompany.id, page: 0 },
    onCompleted: async response => {
      response.csvUploadConfigurations.data.forEach(cUC => csvUploadConfigurations.set(cUC.id, cUC));
      setCsvUploadConfigurations(new Map(csvUploadConfigurations));
      if (response.csvUploadConfigurations.hasNext === true) {
        await refetchCsvUploadConfigurations({
          companyId: currentCompany.id,
          page: ++response.csvUploadConfigurations.page,
        });
      } else {
        setCsvUploadConfigurationsLoading(false);
      }
    },
  });

  const { refetch: refetchTaskSettings } = useQuery<GetTaskSettingsResponse, GetTaskSettingsVariables>(
    TaskSettingsQueries.get,
    {
      variables: { companyId: currentCompany.companyId, page: 0 },
      onCompleted: async response => {
        response.taskSettings.data.forEach(tS => taskSettings.set(tS.id, new TaskSettings(tS)));
        setTaskSettings(new Map(taskSettings));
        if (response.taskSettings.hasNext === true) {
          await refetchTaskSettings({ companyId: currentCompany.id, page: ++response.taskSettings.page });
        } else {
          setTaskSettingsLoading(false);
        }
      },
    },
  );

  const { loading } = useQuery<GetAuthenticationSettingsResponse, GetAuthenticationSettingsVariables>(
    AuthenticationSettingsQueries.get,
    {
      variables: { companyId: currentCompany.companyId },
      onCompleted: async response => {
        if (response.authenticationSettings.data.length) {
          setAuthenticationSettings(response.authenticationSettings.data[0]);
        }
        setUserSettingsLoading(false);
      },
    },
  );

  const { refetch: refetchProductExportConfigurations } = useQuery<
    GetProductExportConfigurationsResponse,
    GetProductExportConfigurationsVariables
  >(ProductExportConfigurationQueries.get, {
    variables: { companyId: currentCompany.id, page: 0 },
    onCompleted: async response => {
      response.productExportConfigurations.data.forEach(pEC =>
        productExportConfigurations.set(pEC.id, new ProductExportConfiguration(pEC)),
      );
      setProductExportConfigurations(new Map(productExportConfigurations));
      if (response.productExportConfigurations.hasNext === true) {
        await refetchProductExportConfigurations({
          companyId: currentCompany.id,
          page: ++response.productExportConfigurations.page,
        });
      } else {
        setProductExportConfigurationsLoading(false);
      }
    },
  });

  const { refetch: refetchProductTransactionExportConfigurations } = useQuery<
    GetProductTransactionExportConfigurationsResponse,
    GetProductTransactionExportConfigurationsVariables
  >(ProductTransactionExportConfigurationQueries.get, {
    variables: { companyId: currentCompany.id, page: 0 },
    onCompleted: async response => {
      response.productTransactionExportConfigurations.data.forEach(ptEC =>
        productTransactionExportConfigurations.set(ptEC.id, new ProductTransactionExportConfiguration(ptEC)),
      );
      setProductTransactionExportConfigurations(new Map(productTransactionExportConfigurations));
      if (response.productTransactionExportConfigurations.hasNext === true) {
        await refetchProductTransactionExportConfigurations({
          companyId: currentCompany.id,
          page: ++response.productTransactionExportConfigurations.page,
        });
      } else {
        setProductTransactionExportConfigurationsLoading(false);
      }
    },
  });

  const { refetch: refetchProductTransactionTableSettings } = useQuery<
    GetProductTransactionTableSettingsResponse,
    GetProductTransactionTableSettingsVariables
  >(ProductTransactionTableSettingsQueries.get, {
    variables: { companyId: currentCompany.id },
    onCompleted: async response => {
      response.productTransactionTableSettings.data.forEach(settings =>
        productTransactionTableSettings.set(settings.id, new ProductTransactionTableSettings(settings)),
      );
      setProductTransactionTableSettings(new Map(productTransactionTableSettings));
      if (response.productTransactionTableSettings.hasNext === true) {
        await refetchProductTransactionTableSettings({
          companyId: currentCompany.id,
          page: ++response.productTransactionTableSettings.page,
        });
      } else {
        setProductTransactionTableSettingsLoading(false);
      }
    },
  });

  const { refetch: refetchReorderRules } = useQuery<GetReorderRulesResponse, GetReorderRulesVariables>(
    ReorderRuleQueries.get,
    {
      variables: { companyId: currentCompany.id },
      onCompleted: async response => {
        response.reorderRules.data.forEach(rule => reorderRules.set(rule.id, new ReorderRule(rule)));
        setReorderRules(new Map(reorderRules));
        if (response.reorderRules.hasNext === true) {
          await refetchReorderRules({ companyId: currentCompany.id, page: ++response.reorderRules.page });
        } else {
          for (const rule of reorderRules.values()) {
            if (groupedReorderRules.has(rule.groupId)) {
              groupedReorderRules.get(rule.groupId)?.push(rule);
            } else {
              groupedReorderRules.set(rule.groupId, [rule]);
            }
          }
          setGroupedReorderRules(new Map(groupedReorderRules));
          setReorderRulesLoading(false);
        }
      },
    },
  );

  const { refetch: refetchReorderUserRules } = useQuery<GetReorderUserRulesResponse, GetReorderUserRulesVariables>(
    ReorderUserRuleQueries.get,
    {
      variables: { companyId: currentCompany.id },
      onCompleted: async response => {
        response.reorderUserRules.data.forEach(rule => reorderUserRules.set(rule.id, new ReorderUserRule(rule)));
        setReorderUserRules(new Map(reorderUserRules));
        if (response.reorderUserRules.hasNext === true) {
          await refetchReorderUserRules({ companyId: currentCompany.id, page: ++response.reorderUserRules.page });
        } else {
          setReorderUserRulesLoading(false);
          for (const rule of reorderUserRules.values()) {
            if (groupedReorderUserRules.has(rule.groupId)) {
              groupedReorderUserRules.get(rule.groupId)?.push(rule);
            } else {
              groupedReorderUserRules.set(rule.groupId, [rule]);
            }
          }
          setGroupedReorderUserRules(groupedReorderUserRules);
        }
      },
      onError: err => console.error(err),
    },
  );

  const { refetch: refetchProductMasterDataExportConfigurations } = useQuery<
    GetProductMasterDataExportConfigurationsResponse,
    GetProductMasterDataExportConfigurationsVariables
  >(ProductMasterDataExportConfigurationQueries.get, {
    variables: { companyId: currentCompany.id, page: 0 },
    onCompleted: async response => {
      response.productMasterDataExportConfigurations.data.forEach(pEC =>
        productMasterDataExportConfigurations.set(pEC.id, new ProductMasterDataExportConfiguration(pEC)),
      );
      setProductMasterDataExportConfigurations(new Map(productMasterDataExportConfigurations));
      if (response.productMasterDataExportConfigurations.hasNext === true) {
        await refetchProductMasterDataExportConfigurations({
          companyId: currentCompany.id,
          page: ++response.productMasterDataExportConfigurations.page,
        });
      } else {
        setProductMasterDataExportConfigurationsLoading(false);
      }
    },
  });

  const productMasterDataContext: ProductMasterDataContextProps = {
    productMasterData,
    setProductMasterData,
    productMasterDataLoading,
  };

  const orderContext: OrderContextProps = {
    orders,
    setOrders,
    ordersLoading,
  };

  const contactContext: ContactContextProps = {
    contacts,
    setContacts,
    contactsLoading,
  };

  const lotContext: LotContextProps = {
    lots,
    setLots,
  };

  const binContext: BinContextProps = {
    bins,
    setBins,
    binsLoading,
  };

  const productContext: ProductContextProps = {
    products,
    setProducts,
    productsLoading,
  };

  const productTransactionContext: ProductTransactionContextProps = {
    productTransactions,
    setProductTransactions,
    productTransactionsLoading,
  };

  const taskContext: TaskContextProps = {
    tasks,
    setTasks,
    tasksLoading,
  };

  const commentContext: CommentContextProps = {
    comments,
    setComments,
    commentsLoading,
  };

  const scanRuleContext: ScanRuleContextProps = {
    scanRules,
    setScanRules,
    scanRulesLoading,
  };

  const taskExportConfigurationContext: TaskExportConfigurationContextProps = {
    taskExportConfigurations,
    setTaskExportConfigurations,
    taskExportConfigurationsLoading,
  };

  const orderExportConfigurationContext: OrderExportConfigurationContextProps = {
    orderExportConfigurations,
    setOrderExportConfigurations,
    orderExportConfigurationsLoading,
  };

  const alertContext: AlertContextProps = {
    alerts,
    setAlerts,
    alertsLoading,
  };

  const triggerContext: TriggerContextProps = {
    triggers,
    setTriggers,
    triggersLoading,
  };

  const billingContext: BillingContextProps = {
    subscription,
    setSubscription,
    billingLoading,
  };

  const customFieldContext: CustomFieldContextProps = {
    customFields,
    setCustomFields,
    customFieldsLoading,
  };

  const orderTableSettingsContext: OrderTableSettingsContextProps = {
    orderTableSettings,
    setOrderTableSettings,
    orderTableSettingsLoading,
  };

  const integrationSettingsContext: IntegrationContextProps = {
    integrationSettings,
    setIntegrationSettings,
    integrationSettingsLoading,
  };

  const csvUploadConfigurationContext: CsvUploadConfigurationContext = {
    csvUploadConfigurations,
    setCsvUploadConfigurations,
    csvUploadConfigurationsLoading,
  };

  const productExportConfigurationContext: ProductExportConfigurationContextProps = {
    productExportConfigurations,
    setProductExportConfigurations,
    productExportConfigurationsLoading,
  };

  const taskSettingsContext: TaskSettingsContextProps = {
    taskSettings,
    setTaskSettings,
    taskSettingsLoading,
  };

  const authenticationSettingsContext: AuthenticationSettingsContextProps = {
    authenticationSettings,
    setAuthenticationSettings,
    authenticationSettingsLoading: userSettingsLoading,
  };

  const productTransactionExportConfigurationContext: ProductTransactionExportConfigurationContextProps = {
    productTransactionExportConfigurations,
    setProductTransactionExportConfigurations,
    productTransactionExportConfigurationsLoading,
  };

  const dashboardContext: DashboardContextProps = {
    cache: dashboardCache,
    setCache: setDashboardCache,
  };

  const productTransactionTableSettingsContext: ProductTransactionTableSettingsContextProps = {
    productTransactionTableSettings,
    setProductTransactionTableSettings,
    productTransactionTableSettingsLoading,
  };

  const reorderRuleContext: ReorderRuleContextProps = {
    reorderRules,
    setReorderRules,
    groupedReorderRules,
    setGroupedReorderRules,
    reorderRulesLoading,
  };

  const reorderUserRuleContext: ReorderUserRuleContextProps = {
    reorderUserRules,
    reorderUserRulesLoading,
    setReorderUserRules,
    groupedReorderUserRules,
    setGroupedReorderUserRules,
  };

  const productMasterDataExportConfigurationsContext: ProductMasterDataExportConfigurationContextProps = {
    productMasterDataExportConfigurations,
    setProductMasterDataExportConfigurations,
    productMasterDataExportConfigurationsLoading,
  };

  const fileContext: FileContextProps = {
    setFileCache,
    fileCache,
    files,
    setFiles,
    filesLoading,
  };

  if (!currentUser) return null;

  const pendingCompanyInvites = (companyRoles.get(currentUser.userId) || []).filter(
    cr => cr.state === CompanyInviteState.pending,
  );

  if (pendingCompanyInvites.length) {
    return <AcceptCompanyInviteScreen role={pendingCompanyInvites[0]} />;
  }

  const initHelpScout = () => {
    const companyRole = companyRoles.get(currentUser.userId)?.find(cr => cr.companyId === currentCompany.id);
    if (!companyRole) return;

    if (beaconInfo()) destroyBeacon();
    if (String(process.env.REACT_APP_ENV) === 'dev') {
      initBeacon(DEV_BEACON_ID);
    } else if (String(process.env.REACT_APP_ENV) === 'staging') {
      initBeacon(STAGING_BEACON_ID);
    } else {
      if (currentCompany.id === process.env.REACT_APP_NETWORK_RAIL_COMPANY_ID) {
        initBeacon(NETWORK_RAIL_BEACON_ID);
      } else {
        initBeacon(PRODUCTION_BEACON_ID);
      }
    }

    setBeaconColor(Colors.getAccentColor(currentCompany));

    if (companyRole.role !== CompanyRole.administrator) {
      setBeaconConfig({ messagingEnabled: false });
    }

    identifyBeacon({
      company: currentCompany.name,
      name: `${currentUser.firstName} ${currentUser.lastName}`,
      email: currentUser.email,
      jobTitle: companyRole.role,
    });
  };

  useEffect(() => {
    initHelpScout();
  }, [companyRoles]);

  const { translations } = useContext(TranslationContext);
  const { customTranslations } = useContext(CustomTranslationContext);

  const [key, setKey] = useState(v4());

  useEffect(() => {
    setKey(v4());
  }, [customTranslations, translations]);

  return (
    <DashboardContext.Provider value={dashboardContext}>
      <ProductMasterDataContext.Provider value={productMasterDataContext}>
        <LotContext.Provider value={lotContext}>
          <BinContext.Provider value={binContext}>
            <ProductContext.Provider value={productContext}>
              <ProductTransactionContext.Provider value={productTransactionContext}>
                <TaskContext.Provider value={taskContext}>
                  <CommentContext.Provider value={commentContext}>
                    <ScanRuleContext.Provider value={scanRuleContext}>
                      <ContactContext.Provider value={contactContext}>
                        <OrderContext.Provider value={orderContext}>
                          <OrderExportConfigurationContext.Provider value={orderExportConfigurationContext}>
                            <TaskExportConfigurationContext.Provider value={taskExportConfigurationContext}>
                              <AlertContext.Provider value={alertContext}>
                                <TriggerContext.Provider value={triggerContext}>
                                  <BillingContext.Provider value={billingContext}>
                                    <CustomFieldContext.Provider value={customFieldContext}>
                                      <OrderTableSettingsContext.Provider value={orderTableSettingsContext}>
                                        <IntegrationContext.Provider value={integrationSettingsContext}>
                                          <CsvUploadConfigurationContext.Provider value={csvUploadConfigurationContext}>
                                            <ProductExportConfigurationContext.Provider
                                              value={productExportConfigurationContext}
                                            >
                                              <ProductTransactionExportConfigurationContext.Provider
                                                value={productTransactionExportConfigurationContext}
                                              >
                                                <ProductTransactionTableSettingsContext.Provider
                                                  value={productTransactionTableSettingsContext}
                                                >
                                                  <TaskSettingsContext.Provider value={taskSettingsContext}>
                                                    <AuthenticationSettingsContext.Provider
                                                      value={authenticationSettingsContext}
                                                    >
                                                      <ReorderRuleContext.Provider value={reorderRuleContext}>
                                                        <ReorderUserRuleContext.Provider value={reorderUserRuleContext}>
                                                          <ProductMasterDataExportConfigurationContext.Provider
                                                            value={productMasterDataExportConfigurationsContext}
                                                          >
                                                            <FileContext.Provider value={fileContext}>
                                                              <HelpScoutBadge />
                                                              <NotificationHandler />
                                                              <Routes>
                                                                {<Route path='/*' element={<HomeScreen key={key} />} />}
                                                              </Routes>
                                                            </FileContext.Provider>
                                                          </ProductMasterDataExportConfigurationContext.Provider>
                                                        </ReorderUserRuleContext.Provider>
                                                      </ReorderRuleContext.Provider>
                                                    </AuthenticationSettingsContext.Provider>
                                                  </TaskSettingsContext.Provider>
                                                </ProductTransactionTableSettingsContext.Provider>
                                              </ProductTransactionExportConfigurationContext.Provider>
                                            </ProductExportConfigurationContext.Provider>
                                          </CsvUploadConfigurationContext.Provider>
                                        </IntegrationContext.Provider>
                                      </OrderTableSettingsContext.Provider>
                                    </CustomFieldContext.Provider>
                                  </BillingContext.Provider>
                                </TriggerContext.Provider>
                              </AlertContext.Provider>
                            </TaskExportConfigurationContext.Provider>
                          </OrderExportConfigurationContext.Provider>
                        </OrderContext.Provider>
                      </ContactContext.Provider>
                    </ScanRuleContext.Provider>
                  </CommentContext.Provider>
                </TaskContext.Provider>
              </ProductTransactionContext.Provider>
            </ProductContext.Provider>
          </BinContext.Provider>
        </LotContext.Provider>
      </ProductMasterDataContext.Provider>
    </DashboardContext.Provider>
  );
}
