import React, { useContext, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import ProfileSettingsPane from './Profile/ProfileSettingsPane';
import StockLocationSettingsPane from './StockLocation/StockLocationSettingsPane';
import UserSettingsPane from './User/UserSettingsPane';
import CompanySettingsPane from './Company/CompanySettingsPane';
import BillingSettingsPane from './Billing/BillingSettingsPanes';
import { CompanyRoleAssignmentContext } from '../../context/CompanyRoleAssignmentContext';
import { UserContext } from '../../context/UserContext';
import { CompanyContext } from '../../context/CompanyContext';
import { CompanyRole } from '../../types/companyRoleAssignment';
import { SuperUserContext } from '../../context/SuperUserContext';
import { testIds } from '../../util/identifiers/identifiers.util';
import { SettingsScreenTabKey } from '../../util/identifiers/SettingsScreenTabKey';
import { StockLocationRole } from '../../types/stockLocationRoleAssignment';
import { StockLocationRoleAssignmentContext } from '../../context/StockLocationRoleAssignmentContext';
import { StockLocationContext } from '../../context/StockLocationContext';
import { StockLocation } from '../../types/stockLocation';
import TriggersSettingsPane from './Triggers/TriggersSettingsPane';
import { FeatureToggles } from '../../types/company';
import { t } from '../../types/translation/Translator';
import { SuperUserRole } from '../../types/superUser';
import Pane from '../../VentoryUI/components/common/Pane/Pane';

const getTabs = (
  role: CompanyRole,
  stockLocationsForUser: StockLocation[],
  featureToggles: FeatureToggles,
  hasStockLocationManagerRole: boolean,
  superUser: boolean,
) =>
  [
    {
      text: t().profile.singular.label,
      path: '/settings/profile',
      key: SettingsScreenTabKey.profile,
    },
    {
      text: t().company.singular.label,
      path: '/settings/company',
      key: SettingsScreenTabKey.company,
    },
    {
      text: t().user.plural.label,
      path: '/settings/users',
      key: SettingsScreenTabKey.users,
    },
    {
      text: t().stockLocation.singular.label,
      path: '/settings/stock_location',
      key: SettingsScreenTabKey.stockLocation,
    },
    {
      text: t().triggers.singular.label,
      path: '/settings/triggers',
      key: SettingsScreenTabKey.triggers,
    },
    {
      text: t().billing.singular.label,
      path: '/settings/billing',
      key: SettingsScreenTabKey.billing,
    },
  ].filter(tab => {
    if (superUser) return true;

    if (!featureToggles.alerts && tab.key === SettingsScreenTabKey.triggers) return false;

    if (role === CompanyRole.administrator) return true;

    if (stockLocationsForUser.length && tab.key === SettingsScreenTabKey.stockLocation) return true;
    if (hasStockLocationManagerRole && tab.key === SettingsScreenTabKey.users) return true;
    if (tab.key === SettingsScreenTabKey.profile) return true;
    return false;
  });

export default function SettingsScreen() {
  const { companyRoles } = useContext(CompanyRoleAssignmentContext);
  const { currentUser } = useContext(UserContext);
  const { currentCompany } = useContext(CompanyContext);
  const { superUser } = useContext(SuperUserContext);
  const { stockLocationRoles } = useContext(StockLocationRoleAssignmentContext);
  const { stockLocations } = useContext(StockLocationContext);

  const [error, setError] = useState('');

  const role = companyRoles.get(currentUser?.userId || '')?.find(cr => cr.companyId === currentCompany.id);

  const possibleStockLocations = [...stockLocations.values()].filter(sl => {
    const role = stockLocationRoles
      .get(sl.id)
      ?.flat()
      .find(u => u.userId === currentUser?.userId)?.role;

    if (
      role === StockLocationRole.STOCK_LOCATION_VIEWER ||
      role === StockLocationRole.STOCK_LOCATION_USER ||
      role === StockLocationRole.STOCK_LOCATION_SUPERVISOR
    )
      return false;
    return true;
  });

  const hasStockLocationManagerRole = !![...stockLocationRoles.values()]
    .flat()
    .find(slr => slr.userId === currentUser?.id && slr.role === StockLocationRole.STOCK_LOCATION_MANAGER);

  return (
    <Pane
      tabs={getTabs(
        role?.role || CompanyRole.employee,
        possibleStockLocations,
        currentCompany.settings.featureToggles,
        hasStockLocationManagerRole,
        superUser?.role === SuperUserRole.administrator,
      )}
      testId={testIds.settingsScreen}
    >
      <Routes>
        <Route path='/profile/*' element={<ProfileSettingsPane />} />
        <Route path='/stock_location' element={<StockLocationSettingsPane />} />
        <Route path='/users' element={<UserSettingsPane />} />
        <Route path='/triggers' element={<TriggersSettingsPane />} />
        <Route path='/company' element={<CompanySettingsPane />} />
        <Route path='/billing' element={<BillingSettingsPane setError={setError} />} />
      </Routes>
    </Pane>
  );
}
